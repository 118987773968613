import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import { searchRigOwners } from '../../services/api';
import RigOwnersTable from './RigOwnersTable';

function RigOwnersSearch() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRigOwners = async () => {
            try {
                setLoading(true);
                const response = await searchRigOwners();
                setResults(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRigOwners();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" sx={{ p: 3 }}>
                {error}
            </Typography>
        );
    }

    return (
        <Box>
            <RigOwnersTable rigOwners={results} />
        </Box>
    );
}

export default RigOwnersSearch; 