import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import IntegrationHealth from './IntegrationHealth';

function Settings() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Settings
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                    <IntegrationHealth />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Settings; 