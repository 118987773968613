import React, { useState } from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Box,
    Typography,
    Button
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';

function RigOwnersTable({ rigOwners }) {
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ key: 'DATECREATED', direction: 'desc' });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'numeric',
            day: 'numeric'
        });
    };

    const toTitleCase = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const formatNRI = (value) => {
        return value ? parseFloat(value).toFixed(8) : '0.00000000';
    };

    const downloadCSV = () => {
        if (!rigOwners?.length) return;

        const headers = [
            'API',
            'Well Name',
            'County',
            'State',
            'Owner Name',
            'Address',
            'City',
            'State',
            'ZIP',
            'NRI',
            'Date Created',
            'Date Modified'
        ];
        
        const csvContent = [
            headers.join(','),
            ...rigOwners.map(record => [
                record.API,
                `"${record.WELLNAME || ''}"`,
                `"${toTitleCase(record.COUNTY) || ''}"`,
                record.STATE,
                `"${record.OWNER || ''}"`,
                `"${record.ADDRESS_1 || ''} ${record.ADDRESS_2 || ''}"`.trim(),
                `"${record.CITY || ''}"`,
                record.OWNER_STATE,
                record.ZIP,
                formatNRI(record.NRI),
                formatDate(record.DATECREATED),
                formatDate(record.DATELASTMODIFIED)
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `rig_owners_${new Date().toISOString().split('T')[0]}.csv`;
        link.click();
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const getSortedRigOwners = () => {
        if (!rigOwners) return [];
        
        return [...rigOwners].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            // Handle dates
            if (sortConfig.key.includes('DATE')) {
                aValue = aValue ? new Date(aValue).getTime() : 0;
                bValue = bValue ? new Date(bValue).getTime() : 0;
            }
            // Handle NRI numeric sorting
            else if (sortConfig.key === 'NRI') {
                aValue = parseFloat(aValue) || 0;
                bValue = parseFloat(bValue) || 0;
            }
            // Handle string sorting
            else {
                aValue = (aValue || '').toString().toLowerCase();
                bValue = (bValue || '').toString().toLowerCase();
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const SortableHeader = ({ column, label }) => (
        <TableCell 
            onClick={() => handleSort(column)}
            style={{ 
                cursor: 'pointer',
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
                border: '1px solid black',
                padding: '8px'
            }}
        >
            {label}
            {sortConfig.key === column && (
                <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
            )}
        </TableCell>
    );

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">
                        Rig Owners ({rigOwners?.length || 0})
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<DownloadIcon />}
                        onClick={downloadCSV}
                    >
                        Download CSV
                    </Button>
                </Box>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <SortableHeader column="API" label="API" />
                                <SortableHeader column="WELLNAME" label="Well Name" />
                                <SortableHeader column="COUNTY" label="County" />
                                <SortableHeader column="STATE" label="State" />
                                <SortableHeader column="OWNER" label="Owner Name" />
                                <SortableHeader column="ADDRESS_1" label="Address" />
                                <SortableHeader column="CITY" label="City" />
                                <SortableHeader column="OWNER_STATE" label="State" />
                                <SortableHeader column="ZIP" label="ZIP" />
                                <SortableHeader column="NRI" label="NRI" />
                                <SortableHeader column="DATECREATED" label="Created" />
                                <SortableHeader column="DATELASTMODIFIED" label="Modified" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getSortedRigOwners().map((record, index) => (
                                <TableRow 
                                    key={`${record.API}-${index}`}
                                    hover
                                    onClick={() => navigate(`/wells/${record.API}`)}
                                    sx={{ 
                                        cursor: 'pointer',
                                        background: index % 2 === 0 ? 'white' : '#fafafa'
                                    }}
                                >
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.API}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.WELLNAME}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {toTitleCase(record.COUNTY)}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.STATE}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.OWNER}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {`${record.ADDRESS_1 || ''} ${record.ADDRESS_2 || ''}`.trim()}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.CITY}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.OWNER_STATE}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {record.ZIP}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>
                                        {formatNRI(record.NRI)}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {formatDate(record.DATECREATED)}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                        {formatDate(record.DATELASTMODIFIED)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Paper>
    );
}

export default RigOwnersTable; 