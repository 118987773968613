import React from 'react'
import { ErrorMessage } from './ErrorMessage'

class APIErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: null }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        }
    }

    componentDidCatch(error, errorInfo) {
        console.error('API Error:', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorMessage
                    message={this.state.error?.message || 'An error occurred while fetching data'}
                    onRetry={() => {
                        this.setState({ hasError: false, error: null })
                        if (this.props.onRetry) {
                            this.props.onRetry()
                        }
                    }}
                />
            )
        }

        return this.props.children
    }
}

export default APIErrorBoundary 