import React, { useState } from 'react';
import { Box, Paper, Typography, CircularProgress, Grid, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { searchPermits } from '../../services/api';
import DataTableWithInfiniteScroll from '../common/DataTableWithInfiniteScroll';
import { formatDate } from '../../utils/formatters';

function PermitSearch() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState({
        api: '',
        operator: '',
        well_name: '',
        county: '',
        state: '',
        permit_type: '',
        well_type: '',
        status: '',
        start_date: null,
        end_date: null
    });

    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        
        try {
            const response = await searchPermits(searchParams);
            console.log('Permit search response:', response);
            setResults(response.data);
        } catch (err) {
            setError(err.message);
            console.error('Error searching permits:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setSearchParams({
            ...searchParams,
            [event.target.name]: event.target.value
        });
    };

    const handleDateChange = (field) => (date) => {
        setSearchParams(prev => ({
            ...prev,
            [field]: date
        }));
    };

    const handleClear = () => {
        setSearchParams({
            api: '',
            operator: '',
            well_name: '',
            county: '',
            state: '',
            permit_type: '',
            well_type: '',
            status: '',
            start_date: null,
            end_date: null
        });
    };

    const columns = [
        { 
            key: 'API',
            label: 'API',
            render: (value) => value || 'N/A'
        },
        { 
            key: 'SUBMITTEDDATE',
            label: 'Permit Date',
            align: 'right',
            sortValue: (row) => row.SUBMITTEDDATE ? new Date(row.SUBMITTEDDATE).getTime() : 0,
            render: (value) => value ? formatDate(value) : 'N/A'
        },
        { 
            key: 'APPROVEDDATE',
            label: 'Approved Date',
            align: 'right',
            sortValue: (row) => row.APPROVEDDATE ? new Date(row.APPROVEDDATE).getTime() : 0,
            render: (value) => value ? formatDate(value) : 'N/A'
        },
        { 
            key: 'CURRENTOPERATOR',
            label: 'Operator',
            render: (value) => value || 'N/A'
        },
        { 
            key: 'WELLNAME',
            label: 'Well Name',
            render: (value) => value || 'N/A'
        },
        { 
            key: 'COUNTY',
            label: 'County',
            render: (value) => value || 'N/A'
        },
        { 
            key: 'STATE',
            label: 'State',
            render: (value) => value || 'N/A'
        },
        { 
            key: 'PERMITPURPOSE',
            label: 'Permit Type',
            render: (value) => value || 'N/A'
        }
    ];

    const handleDownloadCsv = () => {
        if (!results?.length) return;

        // Define CSV columns in the original order
        const csvColumns = [
            { 
                key: 'API',
                header: 'API'
            },
            { 
                key: 'SUBMITTEDDATE',
                header: 'Permit Date',
                getValue: (record) => record.SUBMITTEDDATE ? formatDate(record.SUBMITTEDDATE) : ''
            },
            { 
                key: 'APPROVEDDATE',
                header: 'Approved Date',
                getValue: (record) => record.APPROVEDDATE ? formatDate(record.APPROVEDDATE) : ''
            },
            { 
                key: 'CURRENTOPERATOR',
                header: 'Operator'
            },
            { 
                key: 'WELLNAME',
                header: 'Well Name'
            },
            { 
                key: 'COUNTY',
                header: 'County'
            },
            { 
                key: 'STATE',
                header: 'State'
            },
            { 
                key: 'PERMITPURPOSE',
                header: 'Permit Type'
            }
        ];

        // Helper function to escape CSV values
        const escapeCSV = (value) => {
            if (value === null || value === undefined) return '';
            const stringValue = value.toString();
            if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
                return `"${stringValue.replace(/"/g, '""')}"`;
            }
            return stringValue;
        };

        // Create CSV content
        const headers = csvColumns.map(col => col.header);
        const rows = results.map(record => 
            csvColumns.map(col => {
                let value;
                if (col.getValue) {
                    value = col.getValue(record);
                } else {
                    value = record[col.key] || '';
                }
                return escapeCSV(value);
            })
        );

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'permit_search_results.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <Box>
            <Paper sx={{ p: 3, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="api"
                            label="API Number"
                            value={searchParams.api}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="operator"
                            label="Operator"
                            value={searchParams.operator}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="well_name"
                            label="Well Name"
                            value={searchParams.well_name}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="county"
                            label="County"
                            value={searchParams.county}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="state"
                            label="State"
                            value={searchParams.state}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="permit_type"
                            label="Permit Type"
                            value={searchParams.permit_type}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="well_type"
                            label="Well Type"
                            value={searchParams.well_type}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="status"
                            label="Status"
                            value={searchParams.status}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Start Date"
                            value={searchParams.start_date}
                            onChange={handleDateChange('start_date')}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            maxDate={searchParams.end_date || undefined}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="End Date"
                            value={searchParams.end_date}
                            onChange={handleDateChange('end_date')}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            minDate={searchParams.start_date || undefined}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                disabled={loading}
                            >
                                Search Permits
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleClear}
                                disabled={loading}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && results.length > 0 && (
                <>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Found {results.length} permits
                    </Typography>
                    <DataTableWithInfiniteScroll 
                        data={results}
                        columns={columns}
                        initialSortKey="SUBMITTEDDATE"
                        initialSortDirection="desc"
                        downloadCsv={handleDownloadCsv}
                        downloadButtonLabel="DOWNLOAD PERMITS"
                    />
                </>
            )}
        </Box>
    );
}

export default PermitSearch; 