import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Container } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools/production'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { useIsFetching } from '@tanstack/react-query'
import { APIErrorBoundary } from './components/common'
import { Box, CircularProgress } from '@mui/material'
import { Suspense } from 'react';
import { PageSkeleton } from './components/common/LoadingSkeletons';

import { AuthProvider, useAuth } from './contexts/AuthContext'
import { SearchProvider } from './contexts/SearchContext'
import theme from './theme'
import Navbar from './components/layout/Navbar'
import Dashboard from './components/Dashboard'
import WellDetail from './components/wells/WellDetail'
import Login from './components/auth/Login'
import ResetPassword from './components/auth/ResetPassword';
import RespondentsSearch from './components/respondents/RespondentsSearch';
import Settings from './components/settings/Settings';
import { queryClient } from './lib/queryClient';

// Protected Route wrapper
function PrivateRoute({ children }) {
    const { user, loading } = useAuth()
    
    if (loading) return null
    
    return user ? children : <Navigate to="/login" />
}

function GlobalLoadingIndicator() {
    const isFetching = useIsFetching()
    return isFetching ? (
        <Box 
            position="fixed" 
            bottom={16} 
            right={16} 
            zIndex={2000}
        >
            <CircularProgress size={24} />
        </Box>
    ) : null
}

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
                {({ reset }) => (
                    <APIErrorBoundary onReset={reset}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <AuthProvider>
                                <ThemeProvider theme={theme}>
                                    <SearchProvider>
                                        <CssBaseline />
                                        <Router>
                                            <Suspense fallback={<PageSkeleton />}>
                                                <Routes>
                                                    <Route path="/login" element={<Login />} />
                                                    <Route path="/" element={
                                                        <PrivateRoute>
                                                            <>
                                                                <Navbar />
                                                                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                                                                    <Dashboard />
                                                                </Container>
                                                            </>
                                                        </PrivateRoute>
                                                    } />
                                                    <Route path="/wells/:api" element={
                                                        <PrivateRoute>
                                                            <>
                                                                <Navbar />
                                                                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                                                                    <WellDetail />
                                                                </Container>
                                                            </>
                                                        </PrivateRoute>
                                                    } />
                                                    <Route path="/reset-password" element={<ResetPassword />} />
                                                    <Route path="/respondents" element={
                                                        <PrivateRoute>
                                                            <>
                                                                <Navbar />
                                                                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                                                                    <RespondentsSearch />
                                                                </Container>
                                                            </>
                                                        </PrivateRoute>
                                                    } />
                                                    <Route path="/settings" element={
                                                        <PrivateRoute>
                                                            <>
                                                                <Navbar />
                                                                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                                                                    <Settings />
                                                                </Container>
                                                            </>
                                                        </PrivateRoute>
                                                    } />
                                                </Routes>
                                            </Suspense>
                                        </Router>
                                    </SearchProvider>
                                </ThemeProvider>
                            </AuthProvider>
                        </LocalizationProvider>
                    </APIErrorBoundary>
                )}
            </QueryErrorResetBoundary>
            <GlobalLoadingIndicator />
        </QueryClientProvider>
    )
}

export default App 