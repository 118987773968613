import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;

// Debug log
console.log('Supabase Config:', {
    url: supabaseUrl,
    hasKey: !!supabaseAnonKey,
    nodeEnv: process.env.NODE_ENV
});

if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Missing Supabase configuration');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const auth = {
    signIn: async (email, password) => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password
        })
        if (error) throw error
        return data
    },

    signOut: async () => {
        try {
            // Clear all storage first
            localStorage.clear();
            sessionStorage.clear();
            
            // Attempt to sign out from Supabase
            await supabase.auth.signOut({
                scope: 'local'  // Only clear local session
            });
            
            return true;
        } catch (error) {
            console.error('Error signing out:', error);
            // Even if Supabase call fails, we've cleared local storage
            return true;
        }
    },

    getSession: async () => {
        try {
            const { data: { session }, error } = await supabase.auth.getSession();
            if (error) throw error;
            
            if (!session) {
                return null;
            }
            
            if (session.expires_at && new Date(session.expires_at * 1000) < new Date()) {
                await auth.signOut();
                return null;
            }
            
            return session;
        } catch (error) {
            console.error('Error getting session:', error);
            return null;
        }
    },

    getAccessToken: async () => {
        const session = await auth.getSession();
        return session?.access_token;
    },

    refreshSession: async () => {
        const { data: { session }, error } = await supabase.auth.refreshSession();
        if (error) throw error;
        return session;
    },

    onAuthStateChange: (callback) => {
        return supabase.auth.onAuthStateChange(callback)
    },

    updatePassword: async (newPassword) => {
        try {
            const { error } = await supabase.auth.updateUser({
                password: newPassword
            });
            
            if (error) throw error;
            return { success: true };
        } catch (error) {
            console.error('Error updating password:', error);
            throw error;
        }
    }
}

export { supabase } 