import React, { useMemo } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';
import PropTypes from 'prop-types';

function WellSearchSummary({ wells, commodityPrices }) {
    const toTitleCase = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    // Get the most recent production date
    const mostRecentDate = wells.reduce((latest, well) => {
        const wellDate = well.LAST_PROD_DATE ? new Date(well.LAST_PROD_DATE) : null;
        return !latest || (wellDate && wellDate > latest) ? wellDate : latest;
    }, null);

    // Get previous month
    const previousMonth = new Date(mostRecentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);

    // Filter wells for last two months only and calculate totals
    const { totals, countyStats } = wells.reduce((acc, well) => {
        const wellDate = well.LAST_PROD_DATE ? new Date(well.LAST_PROD_DATE) : null;
        
        // Only include wells from the last two months
        if (wellDate && (
            (wellDate.getMonth() === mostRecentDate?.getMonth() && 
             wellDate.getFullYear() === mostRecentDate?.getFullYear()) ||
            (wellDate.getMonth() === previousMonth?.getMonth() && 
             wellDate.getFullYear() === previousMonth?.getFullYear())
        )) {
            // Total Oil (BBL)
            const lastOil = parseFloat(well.LAST_OIL) || 0;
            acc.totals.totalOil += lastOil;

            // Total Gas (MCF)
            const lastGas = parseFloat(well.LAST_GAS) || 0;
            acc.totals.totalGas += lastGas;

            // Total Revenue
            const oilRevenue = lastOil * commodityPrices.crude_oil;
            const gasRevenue = lastGas * commodityPrices.natural_gas;
            acc.totals.totalRevenue += oilRevenue + gasRevenue;
        }

        // County stats
        if (well.COUNTY) {
            acc.countyStats[well.COUNTY] = (acc.countyStats[well.COUNTY] || 0) + 1;
        }

        return acc;
    }, {
        totals: {
            totalOil: 0,
            totalGas: 0,
            totalRevenue: 0
        },
        countyStats: {}
    });

    const producingWellCount = wells.filter(w => w.LAST_PROD_DATE).length;

    // Fix county count calculation
    const topCounty = useMemo(() => {
        const countyCount = wells.reduce((acc, well) => {
            const county = well.COUNTY;
            if (county) {
                acc[county] = (acc[county] || 0) + 1;
            }
            return acc;
        }, {});

        // Find county with highest count
        return Object.entries(countyCount)
            .sort(([, a], [, b]) => b - a) // Sort by count in descending order
            .map(([county, count]) => ({ county, count }))[0] || { county: 'N/A', count: 0 };
    }, [wells]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        Top County
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        {topCounty.county}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {topCounty.count} wells
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        Total Oil
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        {Math.round(totals.totalOil).toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        BBL
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        Total Gas
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        {Math.round(totals.totalGas).toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        MCF
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                        Total Revenue
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        {totals.totalRevenue.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        USD
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}

WellSearchSummary.propTypes = {
    wells: PropTypes.array.isRequired,
    commodityPrices: PropTypes.shape({
        crude_oil: PropTypes.number.isRequired,
        natural_gas: PropTypes.number.isRequired
    }).isRequired
};

export default WellSearchSummary; 