import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getRecentRespondents } from '../../services/api';
import RespondentsTable from './RespondentsTable';

function RespondentsSearch() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const fetchRespondents = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getRecentRespondents();
            setResults(response.data);
            setLastUpdated(new Date());
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRespondents();

        // Refresh every 5 minutes
        const interval = setInterval(fetchRespondents, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, [fetchRespondents]);

    const handleRefresh = () => {
        fetchRespondents();
    };

    if (loading && !results.length) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" sx={{ p: 3 }}>
                Error: {error}
            </Typography>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">
                    Recent Respondents (Last 100)
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {lastUpdated && (
                        <Typography variant="caption" color="text.secondary">
                            Last updated: {lastUpdated.toLocaleTimeString()}
                        </Typography>
                    )}
                    <Tooltip title="Refresh">
                        <IconButton 
                            onClick={handleRefresh} 
                            disabled={loading}
                            size="small"
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <RespondentsTable 
                respondents={results} 
            />
        </Box>
    );
}

export default RespondentsSearch; 