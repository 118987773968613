import React, { useState, useEffect } from 'react';
import { 
    Grid, 
    Paper, 
    Typography, 
    CircularProgress,
    Box
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { testWellsConnection, testTaxRollsConnection, testCommoditiesConnection, testSupabaseConnection } from '../../services/api';
import axios from 'axios';

function StatusCard({ title, status, error }) {
    return (
        <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {status === 'loading' && <CircularProgress size={24} />}
                    {status === 'success' && (
                        <CheckCircleIcon color="success" sx={{ fontSize: 24 }} />
                    )}
                    {status === 'error' && (
                        <ErrorIcon color="error" sx={{ fontSize: 24 }} />
                    )}
                </Box>
            </Box>
            {status === 'success' && (
                <Typography color="success.main">Connected</Typography>
            )}
            {status === 'error' && (
                <Typography color="error">{error || 'Connection failed'}</Typography>
            )}
        </Paper>
    );
}

function Integrations() {
    const [wdbStatus, setWdbStatus] = useState({ status: 'loading', error: null });
    const [taxRollsStatus, setTaxRollsStatus] = useState({ status: 'loading', error: null });
    const [commoditiesStatus, setCommoditiesStatus] = useState({ status: 'loading', error: null });
    const [supabaseStatus, setSupabaseStatus] = useState({ status: 'loading', error: null });
    const [convey640Status, setConvey640Status] = useState({ status: 'loading', error: null });

    useEffect(() => {
        const checkConnections = async () => {
            // Check WDB Connection
            try {
                await testWellsConnection();
                setWdbStatus({ status: 'success', error: null });
            } catch (error) {
                setWdbStatus({ status: 'error', error: error.message });
            }

            // Check Tax Rolls Connection
            try {
                await testTaxRollsConnection();
                setTaxRollsStatus({ status: 'success', error: null });
            } catch (error) {
                setTaxRollsStatus({ status: 'error', error: error.message });
            }

            // Check Commodities API Connection
            try {
                await testCommoditiesConnection();
                setCommoditiesStatus({ status: 'success', error: null });
            } catch (error) {
                setCommoditiesStatus({ status: 'error', error: error.message });
            }

            // Check Supabase Connection
            try {
                await testSupabaseConnection();
                setSupabaseStatus({ status: 'success', error: null });
            } catch (error) {
                setSupabaseStatus({ status: 'error', error: error.message });
            }

            // Check Convey640 Connection
            try {
                const response = await axios.get('/api/integrations/test-convey640-connection');
                setConvey640Status({ status: 'success', error: null });
            } catch (error) {
                setConvey640Status({ status: 'error', error: error.message });
            }
        };

        checkConnections();
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <StatusCard 
                    title="Wells Database" 
                    status={wdbStatus.status} 
                    error={wdbStatus.error} 
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StatusCard 
                    title="Tax Rolls Database" 
                    status={taxRollsStatus.status} 
                    error={taxRollsStatus.error} 
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StatusCard 
                    title="Commodities API" 
                    status={commoditiesStatus.status} 
                    error={commoditiesStatus.error} 
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StatusCard 
                    title="Supabase" 
                    status={supabaseStatus.status} 
                    error={supabaseStatus.error} 
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <StatusCard 
                    title="Convey640 API" 
                    status={convey640Status.status} 
                    error={convey640Status.error} 
                />
            </Grid>
        </Grid>
    );
}

export default Integrations; 