import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

function TaxRollStats({ records }) {
    // Filter out the specific API before calculating stats
    const filteredRecords = records.filter(record => record.API !== '4200000000');

    // Calculate statistics
    const stats = React.useMemo(() => {
        // First pass to get county counts and totals
        const countyCount = {};
        const initialStats = filteredRecords.reduce((acc, record) => {
            // Count wells per county
            countyCount[record.COUNTY] = (countyCount[record.COUNTY] || 0) + 1;
            
            const nri = parseFloat(record.NRI) || 0;
            const tav = parseFloat(record.TAV) || 0;
            
            // Calculate estimated revenue using the same logic as TaxRollsTable
            const oilRevenue = (parseFloat(record.LAST_OIL) || 0) * 68.93; // WTI price
            const gasRevenue = (parseFloat(record.LAST_GAS) || 0) * 3.30;  // NG price
            const estRevenue = (oilRevenue + gasRevenue) * nri;
            
            // Calculate estimated offer (48 months of revenue)
            const estOffer = estRevenue * 48;

            return {
                averageNri: acc.averageNri + (nri / filteredRecords.length),
                totalValue: acc.totalValue + tav,
                totalEstRevenue: acc.totalEstRevenue + estRevenue,
                totalEstOffer: acc.totalEstOffer + estOffer
            };
        }, {
            averageNri: 0,
            totalValue: 0,
            totalEstRevenue: 0,
            totalEstOffer: 0
        });

        // Find county with highest count
        const topCountyEntry = Object.entries(countyCount)
            .sort(([,a], [,b]) => b - a)[0];
        
        return {
            ...initialStats,
            topCounty: topCountyEntry ? 
                `${topCountyEntry[0]} (${topCountyEntry[1]})` : 
                'N/A'
        };
    }, [filteredRecords]);

    return (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={2.4}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                        Top County
                    </Typography>
                    <Typography variant="h6">
                        {stats.topCounty}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                        Average NRI
                    </Typography>
                    <Typography variant="h6">
                        {stats.averageNri.toFixed(8)}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                        Total Value
                    </Typography>
                    <Typography variant="h6">
                        ${stats.totalValue.toLocaleString()}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                        Est. Revenue
                    </Typography>
                    <Typography variant="h6">
                        ${Math.round(stats.totalEstRevenue).toLocaleString()}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                        Est. Offer
                    </Typography>
                    <Typography variant="h6">
                        ${Math.round(stats.totalEstOffer).toLocaleString()}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default TaxRollStats; 