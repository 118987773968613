import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export function SearchProvider({ children }) {
    const [searchResults, setSearchResults] = useState([]);
    const [searchParams, setSearchParams] = useState({
        api_number: '',
        state: '',
        county: '',
        lease_name: '',
        operator: '',
        show_producing_only: false
    });

    return (
        <SearchContext.Provider value={{ 
            searchResults, 
            setSearchResults, 
            searchParams, 
            setSearchParams 
        }}>
            {children}
        </SearchContext.Provider>
    );
}

export function useSearch() {
    return useContext(SearchContext);
} 