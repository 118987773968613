import React, { useState, useEffect, useRef } from 'react'
import {
    Box,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Typography,
    CircularProgress,
    Grid,
    Chip,
    Alert
} from '@mui/material'
import { searchWells, getCommodityPrices } from '../../services/api'
import WellsTable from './WellsTable'

function WellSearch() {
    const [formValues, setFormValues] = useState({
        api_number: '',
        well_name: '',
        operator: '',
        state: '',
        county: '',
        show_producing_only: false,
        natural_query: ''
    });
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [commodityPrices, setCommodityPrices] = useState({
        crude_oil: 0,
        natural_gas: 0
    });
    const [isLongSearch, setIsLongSearch] = useState(false);
    const longSearchTimeout = useRef(null);
    const [searchMetadata, setSearchMetadata] = useState(null);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const data = await getCommodityPrices();
                setCommodityPrices(data.current);
            } catch (error) {
                console.error('Error fetching commodity prices:', error);
            }
        };
        fetchPrices();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setIsLongSearch(false);
        
        longSearchTimeout.current = setTimeout(() => {
            setIsLongSearch(true);
        }, 5000);
        
        try {
            const response = await searchWells(formValues);
            
            if (formValues.natural_query) {
                setResults(response.data);
                setSearchMetadata(response.metadata);
            } else {
                setResults(response.data);
                setSearchMetadata(null);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
            clearTimeout(longSearchTimeout.current);
            setIsLongSearch(false);
        }
    };

    const renderLoadingState = () => {
        if (!loading) return null;
        
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
                <CircularProgress />
                <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                    {isLongSearch ? 
                        'This search is taking longer than usual. Please wait...' : 
                        'Searching wells...'}
                </Typography>
            </Box>
        );
    };

    const renderSearchMetadata = () => {
        if (!searchMetadata?.query?.intent) return null;

        const { query, performance } = searchMetadata;
        const confidenceColor = query.confidence > 0.8 ? 'success' : 
                              query.confidence > 0.6 ? 'warning' : 'error';

        return (
            <Paper sx={{ p: 2, mb: 2, bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                    <Typography variant="subtitle2">
                        Search Intent:
                    </Typography>
                    <Chip 
                        label={(query.intent || 'UNKNOWN').replace(/_/g, ' ')}
                        color="primary"
                        size="small"
                    />
                    <Chip 
                        label={`${((query.confidence || 0) * 100).toFixed(0)}% Confidence`}
                        color={confidenceColor}
                        size="small"
                        variant="outlined"
                    />
                </Box>
                <Typography variant="caption" color="text.secondary">
                    Found {performance?.total_results || 0} results in {((performance?.execution_time || 0) / 1000).toFixed(2)}s
                </Typography>
            </Paper>
        );
    };

    const handleClear = () => {
        setFormValues({
            api_number: '',
            well_name: '',
            operator: '',
            state: '',
            county: '',
            show_producing_only: false,
            natural_query: ''
        });
        setResults([]);
    };

    return (
        <Box>
            <Paper sx={{ p: 3, mb: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, mb: 2 }}>
                        <TextField
                            fullWidth
                            name="operator"
                            label="Operator"
                            value={formValues.operator}
                            onChange={handleInputChange}
                        />
                        <TextField
                            fullWidth
                            name="well_name"
                            label="Well Name"
                            value={formValues.well_name}
                            onChange={handleInputChange}
                        />
                        <TextField
                            fullWidth
                            name="api_number"
                            label="API Number"
                            value={formValues.api_number}
                            onChange={handleInputChange}
                        />
                        <TextField
                            fullWidth
                            name="state"
                            label="State"
                            value={formValues.state}
                            onChange={handleInputChange}
                        />
                        <TextField
                            fullWidth
                            name="county"
                            label="County"
                            value={formValues.county}
                            onChange={handleInputChange}
                        />
                        <TextField
                            fullWidth
                            name="natural_query"
                            label="Natural Language Search"
                            value={formValues.natural_query}
                            onChange={handleInputChange}
                            margin="normal"
                            helperText="Try searching with natural language, e.g. 'Find wells with high production in Reeves county'"
                            sx={{ 
                                gridColumn: 'span 3',
                                mt: 2
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                        >
                            Search Wells
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClear}
                            disabled={loading}
                        >
                            Clear
                        </Button>
                    </Box>
                </form>
            </Paper>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                    {error.includes('timed out') && (
                        <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                            Try narrowing your search by adding more specific criteria.
                        </Typography>
                    )}
                </Alert>
            )}

            {renderLoadingState()}

            {searchMetadata && renderSearchMetadata()}

            {!loading && results && results.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <WellsTable 
                        wells={results} 
                        commodityPrices={commodityPrices}
                        searchIntent={searchMetadata?.query.intent}
                        onClear={handleClear}
                    />
                </Box>
            )}
        </Box>
    )
}

export default WellSearch 