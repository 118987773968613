import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';

function DataTableWithInfiniteScroll({ 
    data,
    columns,
    initialSortKey,
    initialSortDirection = 'desc',
    onRowClick,
    onRowHover,
    downloadCsv,
    downloadButtonLabel = 'DOWNLOAD CSV'
}) {
    const [displayCount, setDisplayCount] = useState(50);
    const [sortConfig, setSortConfig] = useState({
        key: initialSortKey,
        direction: initialSortDirection
    });
    const loadingRef = useRef(null);

    // Memoize sorted data
    const sortedData = useMemo(() => {
        return [...data].sort((a, b) => {
            if (!sortConfig.key) return 0;

            const column = columns.find(col => col.key === sortConfig.key);
            if (!column) return 0;

            let aValue, bValue;

            // Use sortValue function if provided, otherwise use direct value
            if (column.sortValue) {
                aValue = column.sortValue(a);
                bValue = column.sortValue(b);
            } else {
                aValue = a[sortConfig.key];
                bValue = b[sortConfig.key];
            }

            // Handle null/undefined values
            if (!aValue && !bValue) return 0;
            if (!aValue) return 1;
            if (!bValue) return -1;

            // Compare values
            let comparison = 0;
            if (typeof aValue === 'number') {
                comparison = aValue - bValue;
            } else if (aValue instanceof Date) {
                comparison = aValue.getTime() - bValue.getTime();
            } else {
                comparison = String(aValue).localeCompare(String(bValue));
            }

            return sortConfig.direction === 'asc' ? comparison : -comparison;
        }).slice(0, displayCount);
    }, [data, sortConfig, displayCount, columns]);

    // Intersection Observer callback
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && displayCount < data.length) {
            setDisplayCount(prev => Math.min(prev + 50, data.length));
        }
    }, [displayCount, data.length]);

    // Set up observer
    React.useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        });

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => {
            if (loadingRef.current) {
                observer.unobserve(loadingRef.current);
            }
        };
    }, [handleObserver]);

    const handleSort = (key) => {
        setSortConfig(prev => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    return (
        <Box>
            {downloadCsv && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button 
                        variant="outlined" 
                        startIcon={<DownloadIcon />}
                        onClick={downloadCsv}
                    >
                        {downloadButtonLabel}
                    </Button>
                </Box>
            )}

            <Box sx={{ 
                height: '600px',
                overflowY: 'auto',
                overflowX: 'auto'
            }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 1 }}>
                        <tr>
                            {columns.map(column => (
                                <td 
                                    key={column.key}
                                    onClick={() => handleSort(column.key)}
                                    style={{ 
                                        border: '1px solid black', 
                                        padding: '8px', 
                                        backgroundColor: '#f5f5f5',
                                        cursor: 'pointer',
                                        textAlign: column.align || 'left'
                                    }}
                                >
                                    {column.label}
                                    {sortConfig.key === column.key && (
                                        <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((row, index) => (
                            <tr 
                                key={`row-${index}`}
                                onClick={() => onRowClick?.(row)}
                                onMouseEnter={() => onRowHover?.(row)}
                                style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                            >
                                {columns.map(column => (
                                    <td 
                                        key={column.key}
                                        style={{ 
                                            border: '1px solid black', 
                                            padding: '8px',
                                            textAlign: column.align || 'left'
                                        }}
                                    >
                                        {column.render ? column.render(row[column.key], row) : row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {displayCount < data.length && (
                            <tr ref={loadingRef}>
                                <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                                    <CircularProgress size={24} />
                                    <Box component="span" sx={{ ml: 2 }}>
                                        Loading more rows...
                                    </Box>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Box>
            
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 2,
                color: 'text.secondary'
            }}>
                Showing {sortedData.length} of {data.length} rows
            </Box>
        </Box>
    );
}

DataTableWithInfiniteScroll.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        align: PropTypes.oneOf(['left', 'right', 'center']),
        render: PropTypes.func
    })).isRequired,
    initialSortKey: PropTypes.string.isRequired,
    initialSortDirection: PropTypes.oneOf(['asc', 'desc']),
    onRowClick: PropTypes.func,
    onRowHover: PropTypes.func,
    downloadCsv: PropTypes.func,
    downloadButtonLabel: PropTypes.string
};

export default DataTableWithInfiniteScroll; 