import React from 'react';
import { Box, Paper, Skeleton, Grid } from '@mui/material';

export const PageSkeleton = () => (
    <Box>
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {[...Array(5)].map((_, i) => (
                        <Skeleton key={i} variant="text" width={`${Math.random() * 20 + 60}%`} />
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    {[...Array(5)].map((_, i) => (
                        <Skeleton key={i + 'b'} variant="text" width={`${Math.random() * 20 + 60}%`} />
                    ))}
                </Grid>
            </Grid>
        </Paper>
    </Box>
); 