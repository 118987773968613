import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: 2,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    },
  },
  // Add global error handler
  logger: {
    log: console.log,
    warn: console.warn,
    error: () => {
      // Silently handle prefetch errors
    },
  }
}); 