import React, { useState } from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Box,
    Typography,
    Button
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';

function PermitOwnersTable({ permitOwners }) {
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ key: 'PERMIT_COUNT', direction: 'desc' });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'numeric',
            day: 'numeric'
        });
    };

    const toTitleCase = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const downloadCSV = () => {
        if (!permitOwners?.length) return;

        const headers = [
            'Operator Name',
            'City',
            'State',
            'Permit Count',
            'Latest Permit',
            'Permit Types'
        ];
        
        const csvContent = [
            headers.join(','),
            ...permitOwners.map(record => [
                `"${toTitleCase(record.OPERATOR_NAME) || ''}"`,
                `"${toTitleCase(record.OPERATOR_CITY) || ''}"`,
                record.OPERATOR_STATE,
                record.PERMIT_COUNT,
                formatDate(record.LATEST_PERMIT),
                `"${record.PERMIT_TYPES || ''}"`,
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `permit_owners_${new Date().toISOString().split('T')[0]}.csv`;
        link.click();
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const getSortedPermitOwners = () => {
        if (!permitOwners) return [];
        
        const sortedData = [...permitOwners].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key.includes('DATE') || sortConfig.key === 'LATEST_PERMIT') {
                aValue = aValue ? new Date(aValue).getTime() : 0;
                bValue = bValue ? new Date(bValue).getTime() : 0;
            } else if (sortConfig.key === 'PERMIT_COUNT') {
                aValue = parseInt(aValue) || 0;
                bValue = parseInt(bValue) || 0;
            } else {
                aValue = (aValue || '').toString().toLowerCase();
                bValue = (bValue || '').toString().toLowerCase();
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

        return sortedData;
    };

    const SortableHeader = ({ column, label }) => (
        <TableCell 
            onClick={() => handleSort(column)}
            style={{ 
                cursor: 'pointer',
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
                border: '1px solid black',
                padding: '8px'
            }}
        >
            {label}
            {sortConfig.key === column && (
                <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
            )}
        </TableCell>
    );

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">
                    Permit Owners ({permitOwners?.length || 0})
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={downloadCSV}
                >
                    DOWNLOAD CSV
                </Button>
            </Box>
            <TableContainer>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <SortableHeader column="API" label="API" />
                            <SortableHeader column="WELLNAME" label="Well Name" />
                            <SortableHeader column="COUNTY" label="County" />
                            <SortableHeader column="STATE" label="State" />
                            <SortableHeader column="OWNER" label="Owner Name" />
                            <SortableHeader column="ADDRESS_1" label="Address" />
                            <SortableHeader column="CITY" label="City" />
                            <SortableHeader column="OWNER_STATE" label="State" />
                            <SortableHeader column="ZIP" label="ZIP" />
                            <SortableHeader column="NRI" label="NRI" />
                            <SortableHeader column="DATECREATED" label="Created" />
                            <SortableHeader column="DATELASTMODIFIED" label="Modified" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getSortedPermitOwners().map((record, index) => (
                            <TableRow 
                                key={index}
                                sx={{ 
                                    cursor: 'pointer',
                                    background: index % 2 === 0 ? 'white' : '#fafafa'
                                }}
                            >
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    {toTitleCase(record.OPERATOR_NAME)}
                                </TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    {toTitleCase(record.OPERATOR_CITY)}
                                </TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    {record.OPERATOR_STATE}
                                </TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>
                                    {record.PERMIT_COUNT.toLocaleString()}
                                </TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    {formatDate(record.LATEST_PERMIT)}
                                </TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    {record.PERMIT_TYPES}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default PermitOwnersTable; 