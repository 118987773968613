import React, { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';

const LazyGoogleMap = lazy(() => import('./GoogleMapComponent'));

function MapCard({ well }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const hasLocation = well.SURFACE_LATITUDE && well.SURFACE_LONGITUDE;

    if (!hasLocation) {
        return (
            <Paper sx={{ p: 3, mb: 2 }}>
                <Typography>No location data available</Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ p: 3, mb: 2 }}>
            <Box sx={{ height: 400 }} ref={ref}>
                {inView ? (
                    <Suspense fallback={
                        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    }>
                        <LazyGoogleMap well={well} />
                    </Suspense>
                ) : (
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography color="textSecondary">Loading map...</Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
}

export default MapCard; 