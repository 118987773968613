import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

function ProductionSummary({ wellData }) {
    const formatNumber = (num) => num ? Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) : '0';

    const formatCurrency = (num) => num ? Number(num).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) : '$0';

    // Helper to determine if EUR should be shown
    const shouldShowEUR = {
        oil: (wellData.EUROIL || 0) > (wellData.CUMULATIVEOIL || 0),
        gas: (wellData.EURGAS || 0) > (wellData.CUMULATIVEGAS || 0),
        boe: (wellData.EURBOE || 0) > (wellData.CUMULATIVEBOE || 0)
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>Production Summary</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Metric</TableCell>
                            <TableCell align="right">Volume</TableCell>
                            <TableCell align="right">Revenue</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Total Oil</TableCell>
                            <TableCell align="right">{formatNumber(wellData.CUMULATIVEOIL)} BBL</TableCell>
                            <TableCell align="right">{formatCurrency(wellData.CUMULATIVE_OIL_REVENUE)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Gas</TableCell>
                            <TableCell align="right">{formatNumber(wellData.CUMULATIVEGAS)} MCF</TableCell>
                            <TableCell align="right">{formatCurrency(wellData.CUMULATIVE_GAS_REVENUE)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total BOE</TableCell>
                            <TableCell align="right">{formatNumber(wellData.CUMULATIVEBOE)}</TableCell>
                            <TableCell align="right">{formatCurrency(wellData.CUMULATIVE_BOE_REVENUE)}</TableCell>
                        </TableRow>
                        {shouldShowEUR.oil && (
                            <TableRow>
                                <TableCell>EUR Oil</TableCell>
                                <TableCell align="right">{formatNumber(wellData.EUROIL)} BBL</TableCell>
                                <TableCell align="right">{formatCurrency(wellData.EUR_OIL_REVENUE)}</TableCell>
                            </TableRow>
                        )}
                        {shouldShowEUR.gas && (
                            <TableRow>
                                <TableCell>EUR Gas</TableCell>
                                <TableCell align="right">{formatNumber(wellData.EURGAS)} MCF</TableCell>
                                <TableCell align="right">{formatCurrency(wellData.EUR_GAS_REVENUE)}</TableCell>
                            </TableRow>
                        )}
                        {shouldShowEUR.boe && (
                            <TableRow>
                                <TableCell>EUR BOE</TableCell>
                                <TableCell align="right">{formatNumber(wellData.EURBOE)}</TableCell>
                                <TableCell align="right">{formatCurrency(wellData.EUR_BOE_REVENUE)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                Current Prices: Oil ${wellData.CURRENT_OIL_PRICE}/BBL, Gas ${wellData.CURRENT_GAS_PRICE}/MCF
            </Typography>
        </Box>
    );
}

export default ProductionSummary; 