import { useQuery } from '@tanstack/react-query';
import { getWellDetails, getTaxRollOwners, getCommodityPrices } from '../services/api';

export function useWellDetails(apiNumber) {
    const wellQuery = useQuery({
        queryKey: ['wellDetails', apiNumber],
        queryFn: () => getWellDetails(apiNumber),
        enabled: !!apiNumber,
        staleTime: 1000 * 60 * 60, // 1 hour
    });

    const taxRollQuery = useQuery({
        queryKey: ['taxRollOwners', apiNumber],
        queryFn: async () => {
            const response = await getTaxRollOwners(apiNumber);
            return response.data; // Return just the data array
        },
        enabled: !!wellQuery.data?.API,
        staleTime: 1000 * 60 * 60, // 1 hour
    });

    const pricesQuery = useQuery({
        queryKey: ['commodityPrices'],
        queryFn: getCommodityPrices,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return {
        well: wellQuery.data,
        taxRollOwners: taxRollQuery.data || [], // Return the data directly
        commodityPrices: pricesQuery.data?.current,
        isLoading: wellQuery.isLoading || taxRollQuery.isLoading || pricesQuery.isLoading,
        error: wellQuery.error || taxRollQuery.error || pricesQuery.error,
        refetch: {
            well: wellQuery.refetch,
            taxRolls: taxRollQuery.refetch,
            prices: pricesQuery.refetch
        }
    };
} 