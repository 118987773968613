import React from 'react';
import { LineChart, Line, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

function SparklineChart({ data, dataKey, color = "#1976d2" }) {
    // Custom tooltip formatter
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ 
                    backgroundColor: 'white', 
                    padding: '8px', 
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                }}>
                    <p style={{ margin: 0 }}>{`Date: ${new Date(payload[0].payload.price_date).toLocaleDateString()}`}</p>
                    <p style={{ margin: 0 }}>{`Price: $${payload[0].value.toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };

    if (!data || data.length === 0) {
        return (
            <ResponsiveContainer width="100%" height={50}>
                <LineChart>
                    <YAxis hide={true} />
                </LineChart>
            </ResponsiveContainer>
        );
    }

    return (
        <ResponsiveContainer width="100%" height={50}>
            <LineChart data={data}>
                <YAxis 
                    domain={['auto', 'auto']}
                    hide={true}
                />
                <Tooltip 
                    content={CustomTooltip}
                    cursor={{ stroke: '#666', strokeWidth: 1 }}
                />
                <Line
                    type="monotone"
                    dataKey={dataKey}
                    stroke={color}
                    strokeWidth={1}
                    dot={false}
                    activeDot={{ r: 4, fill: color }}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default SparklineChart; 