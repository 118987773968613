import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Box,
    Alert
} from '@mui/material';
import {
    testWellsConnection,
    testTaxRollsConnection,
    testCommoditiesConnection,
    testSupabaseConnection,
    testConvey640Connection
} from '../../services/api';

function IntegrationHealth() {
    const [statuses, setStatuses] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const integrations = [
        {
            name: 'Wells Database',
            testFunction: testWellsConnection,
            description: 'Connection to Wells Database'
        },
        {
            name: 'Tax Rolls Database',
            testFunction: testTaxRollsConnection,
            description: 'Connection to Tax Rolls Database'
        },
        {
            name: 'Commodities API',
            testFunction: testCommoditiesConnection,
            description: 'Connection to Commodities API'
        },
        {
            name: 'Supabase',
            testFunction: testSupabaseConnection,
            description: 'Connection to Supabase database'
        },
        {
            name: 'Convey640 API',
            testFunction: testConvey640Connection,
            description: 'Connection to Convey640 API for respondents data'
        }
    ];

    useEffect(() => {
        const checkConnections = async () => {
            setLoading(true);
            setError(null);
            const newStatuses = {};

            try {
                for (const integration of integrations) {
                    try {
                        const result = await integration.testFunction();
                        newStatuses[integration.name] = {
                            status: result.success ? 'Connected' : 'Failed',
                            error: result.error
                        };
                    } catch (err) {
                        newStatuses[integration.name] = {
                            status: 'Failed',
                            error: err.message
                        };
                    }
                }
                setStatuses(newStatuses);
            } catch (err) {
                setError('Failed to check integration statuses');
            } finally {
                setLoading(false);
            }
        };

        checkConnections();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 2 }}>
                {error}
            </Alert>
        );
    }

    return (
        <Grid container spacing={3}>
            {integrations.map((integration) => (
                <Grid item xs={12} sm={6} md={4} key={integration.name}>
                    <Card 
                        sx={{ 
                            height: '100%',
                            backgroundColor: statuses[integration.name]?.status === 'Connected' 
                                ? '#e8f5e9' 
                                : '#ffebee'
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" component="div" gutterBottom>
                                {integration.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                {integration.description}
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color={statuses[integration.name]?.status === 'Connected' ? 'success.main' : 'error.main'}
                            >
                                Status: {statuses[integration.name]?.status || 'Unknown'}
                            </Typography>
                            {statuses[integration.name]?.error && (
                                <Typography color="error" variant="body2">
                                    Error: {statuses[integration.name].error}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default IntegrationHealth; 