import React, { useState, useEffect } from 'react'
import { Box, Tab, Tabs, Paper, Typography } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WellSearch from './wells/WellSearch'
import TaxRollSearch from './tax-rolls/TaxRollSearch'
import PermitSearch from './permits/PermitSearch'
import PermitOwnersSearch from './permit-owners/PermitOwnersSearch'
import RigOwnersSearch from './rig-owners/RigOwnersSearch'
import RespondentsSearch from './respondents/RespondentsSearch'
import Integrations from './integrations/Integrations'
import { getCommodityPrices } from '../services/api'
import SparklineChart from './common/SparklineChart'

function TabPanel({ children, value, index }) {
    return (
        <div hidden={value !== index} style={{ padding: '20px 0' }}>
            {value === index && children}
        </div>
    )
}

function CommodityPriceCard({ title, price, unit, historicalData, dataKey }) {
    const formattedPrice = typeof price === 'number' ? 
        parseFloat(price).toFixed(2) : 
        '0.00';

    const chartColor = dataKey === 'crude_oil' ? '#2e7d32' : '#d32f2f';

    return (
        <Paper 
            sx={{ 
                p: 2, 
                display: 'flex', 
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Typography variant="subtitle2" color="text.secondary">
                {title}
            </Typography>
            <Typography variant="h4" component="div" sx={{ mb: 1 }}>
                ${formattedPrice}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                per {unit}
            </Typography>
            <Box sx={{ flexGrow: 1, minHeight: 50 }}>
                <SparklineChart 
                    data={historicalData} 
                    dataKey={dataKey}
                    color={chartColor}
                />
            </Box>
        </Paper>
    );
}

function CommodityPrices() {
    const [prices, setPrices] = useState({
        current: {
            crude_oil: '0.00',
            natural_gas: '0.00'
        },
        units: {
            crude_oil: 'USD/BBL',
            natural_gas: 'USD/MCF'
        }
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                setLoading(true);
                setError(null);
                const data = await getCommodityPrices();
                console.log('Commodity prices response:', data);
                
                if (!data?.current || !data?.historical) {
                    throw new Error('Invalid price data received');
                }
                
                setPrices(data);
            } catch (error) {
                console.error('Error fetching commodity prices:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPrices();
        
        const pollInterval = setInterval(() => {
            const now = new Date();
            const hour = now.getHours();
            if (hour >= 8 && hour <= 16 && now.getDay() >= 1 && now.getDay() <= 5) {
                fetchPrices();
            }
        }, 5 * 60 * 1000);

        return () => clearInterval(pollInterval);
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>Loading...</Paper>
                <Paper sx={{ p: 2, textAlign: 'center' }}>Loading...</Paper>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                <Paper sx={{ p: 2, color: 'error.main' }}>Error: {error}</Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
            <CommodityPriceCard
                title="Crude Oil (WTI)"
                price={parseFloat(prices.current.crude_oil)}
                unit="barrel"
                historicalData={prices.historical || []}
                dataKey="crude_oil"
            />
            <CommodityPriceCard
                title="Natural Gas (NG)"
                price={parseFloat(prices.current.natural_gas)}
                unit="MMBtu"
                historicalData={prices.historical || []}
                dataKey="natural_gas"
            />
        </Box>
    );
}

function Dashboard() {
    const [tabValue, setTabValue] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <Box>
            <Box mb={4}>
                <CommodityPrices />
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={tabValue} 
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Wells" />
                    <Tab label="Tax Rolls" />
                    <Tab label="Permits" />
                    <Tab label="Permit Owners" />
                    <Tab label="Rig Owners" />
                    <Tab label="Respondents" />
                    <Tab label="Integrations" />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <WellSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <TaxRollSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <PermitSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <PermitOwnersSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
                <RigOwnersSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={5}>
                <RespondentsSearch />
            </TabPanel>

            <TabPanel value={tabValue} index={6}>
                <Integrations />
            </TabPanel>
        </Box>
    )
}

export default Dashboard 