import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Paper,
    Typography,
    Grid,
    CircularProgress,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Link,
    Chip,
    Skeleton
} from '@mui/material'
import { getWellDetails, getTaxRollOwners, getCommodityPrices } from '../../services/api'
import { useSearch } from '../../contexts/SearchContext';
import TaxRollOwnersTable from './TaxRollOwnersTable';
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';
import { 
    LineChart, 
    Line, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer 
} from 'recharts';
import MapCard from './MapCard';
import ProductionSummary from './WellDetails';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useQuery } from '@tanstack/react-query';
import { useWellDetails } from '../../hooks/useWellDetails';

const toTitleCase = (str) => {
    if (!str) return '';
    return str.toLowerCase().split(' ').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
};

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'numeric'
    });
}

const formatOCCLink = (api) => {
    if (!api) return null;
    return `https://public.occ.ok.gov/OGCDWellRecords/Search.aspx?searchcommand=%7B[OG%20Well%20Records]:[API%20Number]=%22${api}*%22%7D&cr=1`;
};

const formatNMOCDLink = (api) => {
    if (!api) return null;
    // Format API number with hyphen after state code
    const formattedApi = api.replace(/^(\d{2})(\d{3})(\d{5}).*/, '$1-$2-$3');
    return `https://wwwapps.emnrd.nm.gov/ocd/ocdpermitting/data/WellDetails.aspx?api=${formattedApi}`;
};

const formatCurrency = (num) => num ? Number(num).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}) : '$0';

// Update the calculateDaysBetween function with completion date logic
const calculateDaysBetween = (permitDate, firstProdDate, completionDate) => {
    if (!permitDate) return null;
    
    // Determine which end date to use
    let endDate;
    if (firstProdDate && completionDate) {
        // Convert dates for comparison
        const firstProd = new Date(firstProdDate);
        const completion = new Date(completionDate);
        // Use completion date if first production is earlier
        endDate = firstProd < completion ? completion : firstProd;
    } else {
        // If only one date exists, use that
        endDate = firstProdDate || completionDate;
    }

    if (!endDate) return null;

    const start = new Date(permitDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Add a new date formatter for MM/DD/YYYY format
const formatFullDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit'
    });
};

// Add helper function to check if spud date should be shown
const shouldShowSpudDate = (permitDate, spudDate) => {
    if (!permitDate || !spudDate) return false;
    return new Date(spudDate) > new Date(permitDate);
};

// Add helper function to check if location data exists
const hasLocationData = (well) => {
    return well.SECTION || well.TOWNSHIP || well.RANGE || well.SURVEY || well.ABSTRACT;
};

// Add helper function to format API with dashes
const formatAPIWithDashes = (api) => {
    if (!api) return '';
    return api.replace(/^(\d{2})(\d{3})(\d{5}).*/, '$1-$2-$3');
};

// Add helper function to check if remaining value should be shown
const shouldShowRemainingValue = (well) => {
    return (well.EURBOE || 0) > (well.CUMULATIVEBOE || 0);
};

// Add helper function to calculate remaining value
const calculateRemainingValue = (well) => {
    return (well.EUR_BOE_REVENUE || 0) - (well.CUMULATIVE_BOE_REVENUE || 0);
};

// Add helper function to calculate time producing
const calculateTimeProducing = (firstProdDate) => {
    if (!firstProdDate) return 'N/A';
    
    const start = new Date(firstProdDate);
    const today = new Date();
    const diffTime = Math.abs(today - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // Convert to years with one decimal place
    const years = diffDays / 365.25;
    // Convert to months
    const months = diffDays / 30.44;
    
    if (years >= 1) {
        return `${years.toFixed(1)} Years`;
    } else if (months >= 1) {
        return `${Math.floor(months)} Months`;
    } else {
        return `${diffDays} Days`;
    }
};

// Add helper function to compare dates
const shouldShowPermitToProduction = (permitDate, firstProdDate) => {
    if (!permitDate || !firstProdDate) return false;
    
    const permit = new Date(permitDate);
    const production = new Date(firstProdDate);
    
    // Only show if permit date is before first production date
    return permit < production;
};

const calculatePermitToProduction = (permitDate, firstProdDate, completionDate) => {
    if (!permitDate || !firstProdDate) return 'N/A';
    
    const permit = new Date(permitDate);
    const firstProd = new Date(firstProdDate);
    const completion = completionDate ? new Date(completionDate) : null;
    
    // Use completion date if it exists and is after first production
    const endDate = completion && completion > firstProd ? completion : firstProd;
    
    // Calculate days between permit and production/completion
    const diffTime = endDate - permit;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return `${diffDays} days`;
};

const WellDetailSkeleton = () => (
    <Box>
        {/* Basic Info Section */}
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {[...Array(7)].map((_, i) => (
                        <Skeleton key={i} variant="text" width={`${Math.random() * 20 + 60}%`} />
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    {[...Array(7)].map((_, i) => (
                        <Skeleton key={i + 'b'} variant="text" width={`${Math.random() * 20 + 60}%`} />
                    ))}
                </Grid>
            </Grid>
        </Paper>

        {/* Map Section */}
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="rectangular" height={400} />
        </Paper>

        {/* Production Summary Section */}
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
            <TableContainer>
                <Table>
                    <TableBody>
                        {[...Array(5)].map((_, i) => (
                            <TableRow key={i}>
                                <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                                <TableCell align="right"><Skeleton variant="text" width="40%" /></TableCell>
                                <TableCell align="right"><Skeleton variant="text" width="40%" /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

        {/* Production Chart Section */}
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={400} />
        </Paper>

        {/* Production History Section */}
        <Paper sx={{ p: 3, mb: 2 }}>
            <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {[...Array(3)].map((_, i) => (
                                <TableCell key={i}>
                                    <Skeleton variant="text" width="60%" />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(10)].map((_, i) => (
                            <TableRow key={i}>
                                {[...Array(3)].map((_, j) => (
                                    <TableCell key={j}>
                                        <Skeleton variant="text" width="60%" />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>
);

function WellDetail() {
    const { api: apiNumber } = useParams()
    const navigate = useNavigate()
    const { searchResults } = useSearch();
    
    const { 
        well, 
        taxRollOwners, 
        commodityPrices, 
        isLoading, 
        error,
        refetch 
    } = useWellDetails(apiNumber);

    const handleBack = () => {
        if (searchResults.length > 0) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const downloadProductionHistory = () => {
        if (!well.productionHistory?.length) return;

        // Create CSV content
        const headers = ['Report Date', 'Oil (BBL)', 'Gas (MCF)'];
        const rows = well.productionHistory.map(record => [
            formatDate(record.REPORTDATE),
            record.TOTALOIL || '',
            record.TOTALGAS || ''
        ]);
        
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${well.WELLNAME || well.API}_production_history.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const formatRRCLink = (api) => {
        if (!api) return null;
        // Extract just the county and well codes (8 digits after state code)
        const match = api.match(/^\d{2}(\d{8})/);
        return match ? `https://gis.rrc.texas.gov/gisviewer/?api=${match[1]}` : null;
    };

    // Add this helper to prepare data for the chart
    const prepareChartData = (productionHistory) => {
        if (!productionHistory) return [];
        return productionHistory
            .slice() // Create a copy
            .reverse() // Show oldest to newest
            .map(record => ({
                date: formatDate(record.REPORTDATE),
                oil: record.TOTALOIL || 0,
                gas: record.TOTALGAS || 0
            }));
    };

    const downloadChartAsImage = async () => {
        try {
            const chartElement = document.getElementById('production-chart');
            if (!chartElement) return;

            const canvas = await html2canvas(chartElement);
            const url = canvas.toDataURL('image/jpeg', 1.0);
            const link = document.createElement('a');
            link.download = `${well.WELLNAME || well.API}_production_history.jpg`;
            link.href = url;
            link.click();
        } catch (error) {
            console.error('Error downloading chart:', error);
        }
    };

    const downloadWellDetailsAsPDF = async () => {
        try {
            const contentElement = document.getElementById('well-details-content');
            if (!contentElement) return;

            // Create PDF
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const margin = 10;

            // Add title
            pdf.setFontSize(16);
            pdf.text(`Well Details: ${well.WELLNAME || well.API}`, margin, margin + 10);
            pdf.setFontSize(12);

            // Capture each section separately for better quality
            const sections = [
                'well-info-section',
                'location-section',
                'production-summary-section',
                'production-chart-section',
                'production-history-section',
                'tax-roll-section'
            ];

            let yOffset = margin + 20;

            // Temporarily modify styles for PDF capture
            const elementsToModify = document.querySelectorAll('.MuiPaper-root');
            const originalStyles = [];

            // Store original styles and set white background
            elementsToModify.forEach(element => {
                originalStyles.push({
                    element,
                    background: element.style.background,
                    backgroundColor: element.style.backgroundColor,
                    boxShadow: element.style.boxShadow
                });
                element.style.background = 'white';
                element.style.backgroundColor = 'white';
                element.style.boxShadow = 'none';
            });

            for (const sectionId of sections) {
                const element = document.getElementById(sectionId);
                if (!element) continue;

                const canvas = await html2canvas(element, {
                    backgroundColor: '#ffffff',
                    scale: 2, // Increase quality
                    logging: false,
                    useCORS: true,
                    allowTaint: true
                });

                const imgData = canvas.toDataURL('image/jpeg', 1.0);

                // Calculate image dimensions to fit page width
                const imgWidth = pageWidth - (2 * margin);
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add new page if content would overflow
                if (yOffset + imgHeight > pdf.internal.pageSize.getHeight() - margin) {
                    pdf.addPage();
                    yOffset = margin;
                }

                pdf.addImage(imgData, 'JPEG', margin, yOffset, imgWidth, imgHeight);
                yOffset += imgHeight + 10;
            }

            // Restore original styles
            originalStyles.forEach(({ element, background, backgroundColor, boxShadow }) => {
                element.style.background = background;
                element.style.backgroundColor = backgroundColor;
                element.style.boxShadow = boxShadow;
            });

            // Save the PDF
            pdf.save(`${well.WELLNAME || well.API}_well_details.pdf`);

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    // Handle loading state
    if (isLoading) {
        return <WellDetailSkeleton />;
    }

    // Handle error state
    if (error) {
        return (
            <Paper sx={{ p: 3 }}>
                <Typography color="error">
                    {error.message || 'Error loading well details'}
                </Typography>
                <Button onClick={() => refetch.well()} sx={{ mt: 2 }}>
                    Retry
                </Button>
            </Paper>
        );
    }

    // Not found state
    if (!well) {
        return (
            <Paper sx={{ p: 3 }}>
                <Typography>Well not found</Typography>
                <Button onClick={handleBack} sx={{ mt: 2 }}>
                    Back to Search
                </Button>
            </Paper>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Button onClick={handleBack}>
                    Back to Search
                </Button>
                <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={downloadWellDetailsAsPDF}
                    disabled={!well}
                >
                    Download PDF
                </Button>
            </Box>

            <div id="well-details-content">
                <Grid container spacing={3}>
                    {/* Well Information */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }} id="well-info-section">
                            <Typography variant="h6" gutterBottom>Well Information</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography>
                                        <strong>API:</strong> {formatAPIWithDashes(well.API)}
                                        {well.API && (
                                            <>
                                                {well.STATE === 'NM' && (
                                                    <Button
                                                        href={formatNMOCDLink(well.API)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        startIcon={<LaunchIcon />}
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        NM OCD
                                                    </Button>
                                                )}
                                                {well.STATE === 'OK' && (
                                                    <Button
                                                        href={formatOCCLink(well.API)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        startIcon={<LaunchIcon />}
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        OCC
                                                    </Button>
                                                )}
                                                {well.STATE === 'TX' && (
                                                    <Button
                                                        href={formatRRCLink(well.API)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        startIcon={<LaunchIcon />}
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        Texas RRC
                                                    </Button>
                                                )}
                                                {well.WELLID && (
                                                    <Button
                                                        href={`https://app.welldatabase.com/Browse/Wells#well-details-${well.WELLID}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        startIcon={<LaunchIcon />}
                                                        size="small"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        WellDatabase
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </Typography>
                                    <Typography><strong>Well Name:</strong> {well.WELLNAME}</Typography>
                                    <Typography><strong>Operator:</strong> {well.CURRENTOPERATOR}</Typography>
                                    <Typography><strong>County:</strong> {toTitleCase(well.COUNTY)}</Typography>
                                    <Typography><strong>State:</strong> {well.STATE}</Typography>
                                    <Typography><strong>Status:</strong> {well.REPORTEDWELLSTATUS}</Typography>
                                    <Typography><strong>Time Producing:</strong> {calculateTimeProducing(well.FIRSTPRODDATE)}</Typography>
                                    <Typography><strong>Well LTV:</strong> {formatCurrency(well.CUMULATIVE_BOE_REVENUE)}</Typography>
                                    {shouldShowRemainingValue(well) && (
                                        <Typography><strong>Remaining Value:</strong> {formatCurrency(calculateRemainingValue(well))}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography><strong>Field:</strong> {well.FIELD ? toTitleCase(well.FIELD) : ''}</Typography>
                                    <Typography><strong>Basin:</strong> {well.BASIN}</Typography>
                                    <Typography><strong>Formation:</strong> {well.FORMATION ? toTitleCase(well.FORMATION) : ''}</Typography>
                                    <Typography><strong>Well Type:</strong> {well.WELLTYPE ? toTitleCase(well.WELLTYPE) : ''}</Typography>
                                    <Typography><strong>Permit Date:</strong> {formatFullDate(well.PERMITDATE)}</Typography>
                                    {shouldShowSpudDate(well.PERMITDATE, well.SPUDDATE) && (
                                        <Typography><strong>Spud Date:</strong> {formatFullDate(well.SPUDDATE)}</Typography>
                                    )}
                                    <Typography><strong>Completion Date:</strong> {formatFullDate(well.COMPLETIONDATE)}</Typography>
                                    <Typography><strong>First Production:</strong> {formatFullDate(well.FIRSTPRODDATE)}</Typography>
                                    {shouldShowPermitToProduction(well.PERMITDATE, well.FIRSTPRODDATE) && (
                                        <Typography>
                                            <strong>Permit to Production:</strong> {calculatePermitToProduction(well.PERMITDATE, well.FIRSTPRODDATE, well.COMPLETIONDATE)}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Map Card */}
                    <Grid item xs={12}>
                        <div id="map-section">
                            <MapCard well={well} />
                        </div>
                    </Grid>

                    {/* Location */}
                    {hasLocationData(well) && (
                        <Grid item xs={12}>
                            <Paper sx={{ p: 3 }} id="location-section">
                                <Typography variant="h6" gutterBottom>Location</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        {well.SECTION && (
                                            <Typography><strong>Section:</strong> {well.SECTION}</Typography>
                                        )}
                                        {well.TOWNSHIP && (
                                            <Typography><strong>Township:</strong> {well.TOWNSHIP}</Typography>
                                        )}
                                        {well.RANGE && (
                                            <Typography><strong>Range:</strong> {well.RANGE}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {well.SURVEY && (
                                            <Typography><strong>Survey:</strong> {well.SURVEY}</Typography>
                                        )}
                                        {well.ABSTRACT && (
                                            <Typography><strong>Abstract:</strong> {well.ABSTRACT}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}

                    {/* Production Summary */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }} id="production-summary-section">
                            <ProductionSummary wellData={well} />
                        </Paper>
                    </Grid>

                    {/* Production History Graph */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3, mb: 2 }} id="production-chart-section">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">Production History Graph</Typography>
                                <Button
                                    variant="outlined"
                                    startIcon={<DownloadIcon />}
                                    onClick={downloadChartAsImage}
                                    disabled={!well?.productionHistory?.length}
                                    size="small"
                                >
                                    Download JPG
                                </Button>
                            </Box>
                            <Box id="production-chart" sx={{ height: 400, width: '100%' }}>
                                <ResponsiveContainer>
                                    <LineChart
                                        data={prepareChartData(well.productionHistory)}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis 
                                            dataKey="date"
                                            angle={-45}
                                            textAnchor="end"
                                            height={60}
                                        />
                                        <YAxis yAxisId="left" />
                                        <YAxis yAxisId="right" orientation="right" />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="oil"
                                            name="Oil (BBL)"
                                            stroke="#2e7d32"
                                            dot={false}
                                        />
                                        <Line
                                            yAxisId="right"
                                            type="monotone"
                                            dataKey="gas"
                                            name="Gas (MCF)"
                                            stroke="#d32f2f"
                                            dot={false}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Production History Table */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }} id="production-history-section">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">Production History</Typography>
                                <Button
                                    variant="outlined"
                                    startIcon={<DownloadIcon />}
                                    onClick={downloadProductionHistory}
                                    disabled={!well?.productionHistory?.length}
                                    size="small"
                                >
                                    Download CSV
                                </Button>
                            </Box>
                            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Report Date</TableCell>
                                            <TableCell align="right">Oil (BBL)</TableCell>
                                            <TableCell align="right">Gas (MCF)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {well.productionHistory.map((record, index) => (
                                            <TableRow key={`${record.REPORTDATE}-${index}`}>
                                                <TableCell>
                                                    {formatDate(record.REPORTDATE)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {record.TOTALOIL?.toLocaleString()}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {record.TOTALGAS?.toLocaleString()}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    {/* Tax Roll Owners Table */}
                    {taxRollOwners?.length > 0 && (
                        <Grid item xs={12}>
                            <Paper sx={{ p: 3 }} id="tax-roll-section">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <Typography variant="h6">Tax Roll Owners</Typography>
                                </Box>
                                <TaxRollOwnersTable 
                                    owners={taxRollOwners} 
                                    wellName={well.WELLNAME} 
                                    api={well.API}
                                    well={well}  // Pass the well object
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Box>
    )
}

export default WellDetail 