import axios from 'axios'
import { auth } from './auth';

// Constants for retry logic
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// Update API configuration
const isDevelopment = process.env.NODE_ENV === 'development';
const API_BASE_URL = isDevelopment 
    ? 'http://localhost:8000/api'
    : '/api';

console.log('API Base URL:', API_BASE_URL); // Debug log

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/api',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 10000,
    withCredentials: true
});

// Helper function for delay
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Add response interceptor for error handling
api.interceptors.response.use(
    response => response,
    async error => {
        const { config } = error;
        
        // If retrying is not configured or we've run out of retries
        if (!config || config.retryCount >= MAX_RETRIES) {
            return Promise.reject(error);
        }

        // Increment retry count
        config.retryCount = config.retryCount || 0;
        config.retryCount += 1;

        await sleep(RETRY_DELAY);
        return api(config);
    }
);

const handleResponse = async (response) => {
    if (response.redirected) {
        throw new Error('Authentication required');
    }
    
    if (!response.ok) {
        const error = await response.text();
        throw new Error(error || 'API request failed');
    }
    
    return response.json();
};

// Update apiRequest function
const apiRequest = async (endpoint, options = {}) => {
    try {
        const token = await auth.getAccessToken();
        
        // Use the base URL consistently
        const url = endpoint.startsWith('http') 
            ? endpoint 
            : `${API_BASE_URL}${endpoint.startsWith('/') ? endpoint : `/${endpoint}`}`;
            
        console.log('Making request to:', url); // Debug log
        
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return handleResponse(response);
    } catch (error) {
        console.error('API Request failed:', error); // Debug log
        if (error.message === 'Session expired' || error.message === 'No active session') {
            window.location.href = '/login';
            throw new Error('Please log in again');
        }
        throw error;
    }
};

// Wells API - single unified searchWells function
export const searchWells = async (params) => {
    try {
        // Check if this is a natural language query
        if (params.natural_query) {
            const response = await api.post('/wells/search/natural', {
                query: params.natural_query
            });
            
            if (response.data.success) {
                return {
                    data: response.data.data,
                    metadata: response.data.metadata
                };
            }
        } else {
            // Regular search
            const response = await api.get('/wells/search', { 
                params,
                timeout: 30000
            });
            return response.data;
        }
    } catch (error) {
        let errorMessage = 'Error searching wells';
        if (error.code === 'ECONNABORTED') {
            errorMessage = 'Search request timed out. Please try refining your search criteria.';
        } else if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
        }
        throw new Error(errorMessage);
    }
};

// Update getWellDetails with longer timeout and better error handling
export const getWellDetails = async (apiNumber) => {
    try {
        const response = await api.get(`/wells/${apiNumber}`, {
            timeout: 60000 // Increase to 60 seconds
        });
        
        if (!response.data.success) {
            throw new Error(response.data.error || 'Failed to fetch well details');
        }
        return response.data.data;
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            console.warn('Well details request timed out - data may be incomplete');
            // If we have partial data in the error response, return it
            if (error.response?.data?.data) {
                return error.response.data.data;
            }
        }
        console.error('Well details response:', error);
        throw error;
    }
};

// Tax Rolls API
export const searchTaxRolls = async (params) => {
    try {
        const response = await api.get('/tax-rolls/search', { 
            params,
            timeout: 30000
        });
        
        return response.data;
    } catch (error) {
        console.error('Tax rolls search error:', error);
        throw error;
    }
};

// Commodities API
export const getCommodityPrices = async (retryCount = 0) => {
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 1000; // 1 second
    
    try {
        const response = await axios.get('/api/commodities/prices', {
            timeout: 15000, // Increase timeout to 15 seconds
        });
        
        if (!response.data) {
            throw new Error('No data received from commodity prices API');
        }
        
        return response.data;
    } catch (error) {
        if (retryCount < MAX_RETRIES && 
            (error.code === 'ECONNABORTED' || error.response?.status === 500)) {
            console.log(`Retrying commodity prices fetch (attempt ${retryCount + 1})...`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            return getCommodityPrices(retryCount + 1);
        }
        
        // If all retries failed or other error, return default values
        console.error('Error fetching commodity prices:', error);
        return {
            current: {
                crude_oil: 0,
                natural_gas: 0
            },
            units: {
                crude_oil: 'USD/BBL',
                natural_gas: 'USD/MCF'
            }
        };
    }
};

// Integration Tests API
export const testWellsConnection = async () => {
    try {
        const response = await api.get('/integrations/test-wells-connection')
        return response.data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error testing wells connection')
    }
}

export const testTaxRollsConnection = async () => {
    try {
        const response = await api.get('/integrations/test-taxrolls-connection')
        return response.data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error testing tax rolls connection')
    }
}

export const getTaxRollOwners = async (apiNumber, retryCount = 0) => {
    try {
        const response = await api.get(`/tax-rolls/owners/${apiNumber}`);
        console.log('Tax roll owners response:', response.data);
        
        if (!response.data.success) {
            throw new Error(response.data.error || 'Failed to fetch tax roll owners');
        }
        
        return response.data;
    } catch (error) {
        if (retryCount < MAX_RETRIES && (error.code === 'ERR_NETWORK' || error.response?.status === 500)) {
            console.log(`Retrying request (attempt ${retryCount + 1})...`);
            await sleep(RETRY_DELAY);
            return getTaxRollOwners(apiNumber, retryCount + 1);
        }
        console.error('Error fetching tax roll owners:', error);
        throw new Error(error.response?.data?.error || 'Error fetching tax roll owners');
    }
};

export const testCommoditiesConnection = async () => {
    try {
        const response = await api.get('/integrations/test-commodities-connection');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error testing commodities connection');
    }
};

export const testSupabaseConnection = async () => {
    try {
        const response = await api.get('/integrations/test-supabase-connection');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error testing Supabase connection');
    }
};

export const searchPermits = async (params) => {
    try {
        const response = await api.post('/permits/search', params);
        return response.data;
    } catch (error) {
        console.error('Error searching permits:', error);
        throw error;
    }
};

export const searchPermitOwners = async () => {
    try {
        const response = await api.get('/permit-owners');
        return response.data;
    } catch (error) {
        console.error('Error searching permit owners:', error);
        throw error;
    }
};

export const searchRigOwners = async () => {
    try {
        const response = await api.get('/rig-owners');
        return response.data;
    } catch (error) {
        console.error('Error searching rig owners:', error);
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        const response = await api.post('/auth/reset-password', { email });
        return response.data;
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};

// Respondents API
export const searchRespondents = async (params) => {
    const response = await axios.get('/api/respondents/search', { params });
    return response.data;
};

export const getRecentRespondents = async () => {
    const response = await axios.get('/api/respondents/recent');
    return response.data;
};

export const testConvey640Connection = async () => {
    try {
        const response = await api.get('/integrations/test-convey640-connection');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error testing Convey640 connection');
    }
};

// Add this function alongside existing API functions
export const semanticTaxRollSearch = async (query) => {
    try {
        const response = await axios.post('/api/tax-rolls/semantic-search', { query })
        return response.data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Failed to perform semantic search')
    }
} 