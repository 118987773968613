import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
    Box,
    Paper,
    TextField,
    Button,
    Typography,
    Alert
} from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'
import ForgotPassword from './ForgotPassword'

function Login() {
    const navigate = useNavigate()
    const { signIn } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setError('')
            setLoading(true)
            await signIn(email, password)
            navigate('/')
        } catch (error) {
            setError('Failed to sign in: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    if (showForgotPassword) {
        return <ForgotPassword onBackToLogin={() => setShowForgotPassword(false)} />
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: '100vh'
        }}>
            <Paper sx={{ p: 4, maxWidth: 400, width: '100%' }}>
                <Typography 
                    variant="h5" 
                    component="h1" 
                    align="center" 
                    gutterBottom
                    sx={{ mb: 4 }}
                >
                    McCleskey.ai
                </Typography>
                
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        required
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={loading}
                        sx={{ mt: 3 }}
                    >
                        Sign In
                    </Button>
                </form>
                
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    mt: 2
                }}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => setShowForgotPassword(true)}
                    >
                        Forgot Password?
                    </Link>
                </Box>
            </Paper>
        </Box>
    )
}

export default Login 