import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box
} from '@mui/material'
import { useAuth } from '../../contexts/AuthContext'

function Navbar() {
    const { signOut } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const handleLogout = async () => {
        try {
            setLoading(true);
            await signOut();
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Error signing out:', error);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login', { replace: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography 
                    variant="h6" 
                    component={RouterLink} 
                    to="/"
                    sx={{ 
                        flexGrow: 1,
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.8)'
                        }
                    }}
                >
                    McCleskey.ai
                </Typography>
                <Box>
                    <Button 
                        color="inherit" 
                        onClick={handleLogout}
                        disabled={loading}
                    >
                        {loading ? 'LOGGING OUT...' : 'LOGOUT'}
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar; 