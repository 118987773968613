import React, { createContext, useState, useContext, useEffect } from 'react'
import { auth } from '../services/auth'

const AuthContext = createContext({})

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const session = await auth.getSession()
                setUser(session?.user ?? null)
            } catch (error) {
                console.error('Error checking auth status:', error)
                setUser(null)
            } finally {
                setLoading(false)
            }
        }

        initializeAuth()

        const { data: { subscription } } = auth.onAuthStateChange((event, session) => {
            setUser(session?.user ?? null)
        })

        return () => {
            subscription?.unsubscribe()
        }
    }, [])

    const value = {
        user,
        loading,
        signIn: auth.signIn,
        signOut: auth.signOut
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext) 