import * as yup from 'yup'

// Common validation schemas
export const apiNumberSchema = yup
    .string()
    .matches(/^\d{10}$/, 'API number must be 10 digits')
    .nullable()

export const stateSchema = yup
    .string()
    .matches(/^[A-Z]{2}$/, 'State must be 2 uppercase letters')
    .nullable()

// Well search form validation
export const wellSearchSchema = yup.object({
    api_number: apiNumberSchema,
    state: stateSchema,
    county: yup.string().nullable(),
    lease_name: yup.string().nullable(),
    operator: yup.string().nullable()
})

// Tax roll search form validation
export const taxRollSearchSchema = yup.object({
    owner_name: yup.string().nullable(),
    county: yup.string().nullable(),
    operator_name: yup.string().nullable(),
    api: apiNumberSchema
})

// Permit search form validation
export const permitSearchSchema = yup.object().shape({
    api: yup.string()
        .matches(/^\d{0,10}$/, 'API number must be up to 10 digits')
        .optional(),
    operator: yup.string()
        .max(100, 'Operator name must be less than 100 characters')
        .optional(),
    well_name: yup.string()
        .max(100, 'Well name must be less than 100 characters')
        .optional(),
    county: yup.string()
        .max(50, 'County must be less than 50 characters')
        .optional(),
    state: yup.string()
        .matches(/^[A-Z]{0,2}$/, 'State must be a 2-letter code')
        .optional(),
    permit_type: yup.string()
        .max(50, 'Permit type must be less than 50 characters')
        .optional(),
    well_type: yup.string()
        .max(50, 'Well type must be less than 50 characters')
        .optional(),
    status: yup.string()
        .max(50, 'Status must be less than 50 characters')
        .optional()
});

// Validation helper function
export const validateForm = async (schema, values) => {
    try {
        await schema.validate(values, { abortEarly: false })
        return { isValid: true, errors: {} }
    } catch (err) {
        const errors = err.inner.reduce((acc, error) => {
            acc[error.path] = error.message
            return acc
        }, {})
        return { isValid: false, errors }
    }
} 