import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress, TextField, Grid, Button } from '@mui/material';
import { searchTaxRolls, getCommodityPrices, semanticTaxRollSearch } from '../../services/api';
import TaxRollStats from './TaxRollStats';
import TaxRollSearchForm from '../forms/TaxRollSearchForm';
import DataTableWithInfiniteScroll from '../common/DataTableWithInfiniteScroll';
import { formatCurrency, formatDate } from '../../utils/formatters';

function TaxRollSearch() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [naturalQuery, setNaturalQuery] = useState('');
    const [searchParams, setSearchParams] = useState({
        owner_name: '',
        owner_city: '',
        owner_state: '',
        lease_name: '',
        operator: '',
        county: '',
        api: ''
    });
    const [commodityPrices, setCommodityPrices] = useState({
        crude_oil: 0,
        natural_gas: 0
    });

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const data = await getCommodityPrices();
                setCommodityPrices(data.current);
            } catch (error) {
                console.error('Error fetching commodity prices:', error);
            }
        };
        fetchPrices();
    }, []);

    const handleChange = (event) => {
        setSearchParams({
            ...searchParams,
            [event.target.name]: event.target.value
        });
    };

    const handleClear = () => {
        setSearchParams({
            owner_name: '',
            owner_city: '',
            owner_state: '',
            lease_name: '',
            operator: '',
            county: '',
            api: ''
        });
        setNaturalQuery('');
    };

    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        
        try {
            let response;
            if (naturalQuery) {
                response = await semanticTaxRollSearch(naturalQuery);
                setResults(response.data);
            } else {
                response = await searchTaxRolls(searchParams);
                if (response.success) {
                    setResults(response.data);
                } else {
                    setError(response.error || 'Search failed');
                }
            }
        } catch (error) {
            setError(error.message || 'An error occurred during search');
        } finally {
            setLoading(false);
        }
    };

    const formatProdDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric',
            month: 'short'
        });
    };

    const columns = [
        { 
            key: 'OWNER', 
            label: 'Owner',
            render: (value) => value ? toTitleCase(value) : ''
        },
        { key: 'WELL', label: 'Well' },
        { key: 'OPERATOR', label: 'Operator' },
        { key: 'COUNTY', label: 'County' },
        { 
            key: 'NRI', 
            label: 'NRI',
            align: 'right',
            render: (value) => value ? parseFloat(value).toFixed(8) : '0.00000000'
        },
        { key: 'INTEREST TYPE', label: 'Interest Type' },
        { key: 'API', label: 'API' },
        { key: 'TAX YEAR', label: 'Tax Year' },
        { 
            key: 'TAV', 
            label: 'TAV',
            align: 'right',
            render: (value) => formatCurrency(value)
        },
        { 
            key: 'LAST_PROD_DATE', 
            label: 'Last Prod',
            align: 'right',
            render: (value) => formatProdDate(value)
        },
        { 
            key: 'EST_REVENUE', 
            label: 'Est Revenue',
            align: 'right',
            sortValue: (row) => calculateEstRevenue(row),
            render: (value, row) => {
                const monthlyRevenue = calculateEstRevenue(row);
                return formatCurrency(monthlyRevenue);
            }
        },
        { 
            key: 'EST_OFFER', 
            label: 'Est Offer',
            align: 'right',
            sortValue: (row) => calculateEstRevenue(row) * 48,
            render: (value, row) => {
                const monthlyRevenue = calculateEstRevenue(row);
                return formatCurrency(monthlyRevenue * 48);
            }
        }
    ];

    const toTitleCase = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const calculateEstRevenue = (record) => {
        const nri = parseFloat(record.NRI) || 0;
        const monthlyRevenue = (parseFloat(record.LAST_OIL) || 0) * commodityPrices.crude_oil + 
                             (parseFloat(record.LAST_GAS) || 0) * commodityPrices.natural_gas;
        return monthlyRevenue * nri;
    };

    const handleDownloadCsv = () => {
        if (!results?.length) return;

        // Define CSV columns in the original order
        const csvColumns = [
            { key: 'OWNER', header: 'Owner' },
            { key: 'ADDRESS_1', header: 'Address 1' },
            { key: 'ADDRESS_2', header: 'Address 2' },
            { key: 'CITY', header: 'City' },
            { key: 'STATE', header: 'State' },
            { key: 'ZIP', header: 'ZIP' },
            { key: 'WELL', header: 'Well' },
            { key: 'OPERATOR', header: 'Operator' },
            { key: 'COUNTY', header: 'County' },
            { key: 'NRI', header: 'NRI' },
            { key: 'INTEREST TYPE', header: 'Interest Type' },
            { key: 'API', header: 'API' },
            { key: 'TAX YEAR', header: 'Tax Year' },
            { key: 'TAV', header: 'TAV' },
            { 
                key: 'LAST_PROD_DATE', 
                header: 'Last Prod',
                getValue: (record) => record.LAST_PROD_DATE ? formatProdDate(record.LAST_PROD_DATE) : ''
            },
            { 
                key: 'EST_REVENUE', 
                header: 'Est Revenue',
                getValue: (record) => {
                    const nri = parseFloat(record.NRI) || 0;
                    const oilRevenue = (parseFloat(record.LAST_OIL) || 0) * commodityPrices.crude_oil;
                    const gasRevenue = (parseFloat(record.LAST_GAS) || 0) * commodityPrices.natural_gas;
                    return (oilRevenue + gasRevenue) * nri;
                }
            },
            { 
                key: 'EST_OFFER', 
                header: 'Est Offer',
                getValue: (record) => {
                    const nri = parseFloat(record.NRI) || 0;
                    const oilRevenue = (parseFloat(record.LAST_OIL) || 0) * commodityPrices.crude_oil;
                    const gasRevenue = (parseFloat(record.LAST_GAS) || 0) * commodityPrices.natural_gas;
                    return ((oilRevenue + gasRevenue) * nri) * 48;
                }
            }
        ];

        // Helper function to escape CSV values
        const escapeCSV = (value) => {
            if (value === null || value === undefined) return '';
            const stringValue = value.toString();
            if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
                return `"${stringValue.replace(/"/g, '""')}"`;
            }
            return stringValue;
        };

        // Create CSV content
        const headers = csvColumns.map(col => col.header);
        const rows = results.map(record => 
            csvColumns.map(col => {
                let value;
                if (col.getValue) {
                    value = col.getValue(record);
                } else if (col.key === 'NRI') {
                    value = record[col.key] ? parseFloat(record[col.key]).toFixed(8) : '0.00000000';
                } else if (col.key === 'TAV') {
                    value = record[col.key] ? parseFloat(record[col.key]) : 0;
                } else {
                    value = record[col.key] || '';
                }
                return escapeCSV(value);
            })
        );

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'tax_roll_search_results.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <Box>
            <Paper sx={{ p: 3, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="owner_name"
                            label="Owner Name"
                            value={searchParams.owner_name}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="owner_city"
                            label="Owner City"
                            value={searchParams.owner_city}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="owner_state"
                            label="Owner State"
                            value={searchParams.owner_state}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="lease_name"
                            label="Lease Name"
                            value={searchParams.lease_name}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="operator"
                            label="Operator"
                            value={searchParams.operator}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="county"
                            label="County"
                            value={searchParams.county}
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Natural Language Search"
                            variant="outlined"
                            value={naturalQuery}
                            onChange={(e) => setNaturalQuery(e.target.value)}
                            helperText="Try searching with natural language, e.g. 'Find owners with high interest in Reeves county'"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                disabled={loading}
                            >
                                Search Tax Records
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleClear}
                                disabled={loading}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && results.length > 0 && (
                <>
                    <TaxRollStats records={results} />
                    <DataTableWithInfiniteScroll 
                        data={results}
                        columns={columns}
                        initialSortKey="NRI"
                        initialSortDirection="desc"
                        downloadCsv={handleDownloadCsv}
                        downloadButtonLabel="DOWNLOAD TAX ROLLS"
                    />
                </>
            )}
        </Box>
    );
}

export default TaxRollSearch; 