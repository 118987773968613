import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography
} from '@mui/material';

function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

function RespondentsTable({ respondents }) {
    if (!respondents?.length) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>No respondents found</Typography>
            </Box>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Applicant</TableCell>
                        <TableCell>County</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Legal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {respondents.map((respondent, index) => (
                        <TableRow key={index} hover>
                            <TableCell>{formatDate(respondent._date)}</TableCell>
                            <TableCell>{respondent.applicant}</TableCell>
                            <TableCell>{respondent.county}</TableCell>
                            <TableCell>{respondent.city}</TableCell>
                            <TableCell>{respondent.address}</TableCell>
                            <TableCell>
                                {[
                                    respondent.twn && `TWN ${respondent.twn}`,
                                    respondent.rng && `RNG ${respondent.rng}`,
                                    respondent.sec && `SEC ${respondent.sec}`,
                                    respondent.str && respondent.str
                                ].filter(Boolean).join(', ')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default RespondentsTable; 