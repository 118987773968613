import React from 'react'
import { Paper, Typography, Button, Box } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

export const ErrorMessage = ({ 
    message = 'An error occurred', 
    onRetry = null 
}) => {
    return (
        <Paper 
            sx={{ 
                p: 3, 
                bgcolor: 'error.light', 
                color: 'error.contrastText' 
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom>
                    Error
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {message}
                </Typography>
                {onRetry && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        onClick={onRetry}
                        sx={{ mt: 2 }}
                    >
                        Retry
                    </Button>
                )}
            </Box>
        </Paper>
    )
} 