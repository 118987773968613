import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { formatDate } from '../../utils/formatters';
import WellSearchSummary from './WellSearchSummary';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { getWellDetails } from '../../services/api';
import { useQueryClient } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';

function WellsTable({ wells, commodityPrices, searchIntent, onClear }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [displayCount, setDisplayCount] = useState(50); // Initial number of rows
    const loadingRef = useRef(null);
    
    // Define keyMap at component scope
    const keyMap = {
        'API': 'API',
        'WELL NAME': 'WELLNAME',
        'OPERATOR': 'CURRENTOPERATOR',
        'COUNTY': 'COUNTY',
        'STATE': 'STATE',
        'STATUS': 'REPORTEDWELLSTATUS',
        'LAST PROD': 'LAST_PROD_DATE',
        'LAST OIL': 'LAST_OIL',
        'LAST GAS': 'LAST_GAS',
        'GROSS REVENUE': 'GROSS_REVENUE'
    };
    
    // Add back the sorting state
    const [sortConfig, setSortConfig] = useState({
        key: 'LAST_PROD_DATE',
        direction: 'desc'
    });

    // Add debounce and error tracking
    const prefetchTimeouts = useRef({});
    const failedPrefetches = useRef(new Set());

    // Improved prefetch function with debounce and error handling
    const handleRowHover = useCallback((api) => {
        // Skip if this API has failed before
        if (failedPrefetches.current.has(api)) return;

        // Clear existing timeout for this API
        if (prefetchTimeouts.current[api]) {
            clearTimeout(prefetchTimeouts.current[api]);
        }

        // Set new timeout
        prefetchTimeouts.current[api] = setTimeout(() => {
            // Check if data is already in the query cache
            const cachedData = queryClient.getQueryData(['wellDetails', api]);
            if (!cachedData) {
                queryClient.prefetchQuery({
                    queryKey: ['wellDetails', api],
                    queryFn: () => getWellDetails(api),
                    staleTime: 1000 * 60 * 60, // 1 hour
                    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
                    retry: 1, // Only retry once
                    onError: () => {
                        // Add to failed set if prefetch fails
                        failedPrefetches.current.add(api);
                        // Clean up after 5 minutes
                        setTimeout(() => {
                            failedPrefetches.current.delete(api);
                        }, 1000 * 60 * 5);
                    }
                });
            }
        }, 200); // 200ms debounce

        // Cleanup on unmount
        return () => {
            Object.values(prefetchTimeouts.current).forEach(clearTimeout);
        };
    }, [queryClient]);

    // Memoize sorted wells to prevent unnecessary re-sorting
    const sortedWells = useMemo(() => {
        return [...wells].sort((a, b) => {
            if (!a[sortConfig.key] && !b[sortConfig.key]) return 0;
            if (!a[sortConfig.key]) return 1;
            if (!b[sortConfig.key]) return -1;
            
            let comparison = 0;
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            // Special handling for gross revenue
            if (sortConfig.key === 'GROSS_REVENUE') {
                const aRevenue = calculateGrossRevenue(a);
                const bRevenue = calculateGrossRevenue(b);
                comparison = aRevenue - bRevenue;
            }
            // Handle numeric fields
            else if (['LAST_OIL', 'LAST_GAS'].includes(sortConfig.key)) {
                comparison = (parseFloat(aValue) || 0) - (parseFloat(bValue) || 0);
            }
            // Handle date fields
            else if (['LAST_PROD_DATE'].includes(sortConfig.key)) {
                const dateA = aValue ? new Date(aValue).getTime() : 0;
                const dateB = bValue ? new Date(bValue).getTime() : 0;
                comparison = dateA - dateB;
            }
            // Default string comparison
            else {
                comparison = String(aValue || '').localeCompare(String(bValue || ''));
            }

            return sortConfig.direction === 'asc' ? comparison : -comparison;
        });
    }, [wells, sortConfig, commodityPrices]);

    // Intersection Observer callback
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && displayCount < wells.length) {
            setDisplayCount(prev => Math.min(prev + 50, wells.length));
        }
    }, [displayCount, wells.length]);

    // Set up observer
    React.useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        });

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => {
            if (loadingRef.current) {
                observer.unobserve(loadingRef.current);
            }
        };
    }, [handleObserver]);

    // Add sorting function
    const sortData = (key) => {
        const dataKey = keyMap[key] || key;
        let direction = 'asc';
        if (sortConfig.key === dataKey && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: dataKey, direction });
    };

    // Format date to Month-Year
    const formatProdDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            year: 'numeric',
            month: 'short'
        });
    };

    // Get additional columns based on search intent (only for natural language search)
    const getIntentBasedColumns = () => {
        if (!searchIntent) return []; // Return empty array for regular search

        const intentColumns = {
            PRODUCTION_DATA: [
                { id: 'last_prod_date', label: 'Last Production' },
                { id: 'last_oil', label: 'Oil (BBL)' },
                { id: 'last_gas', label: 'Gas (MCF)' },
                { id: 'revenue', label: 'Revenue' }
            ],
            LOCATION_SEARCH: [
                { id: 'surface_latitude', label: 'Latitude' },
                { id: 'surface_longitude', label: 'Longitude' }
            ],
            // Add other intent-specific columns as needed
        };

        return intentColumns[searchIntent] || [];
    };

    // Calculate gross revenue (keep existing function)
    const calculateGrossRevenue = (well) => {
        const oilRevenue = (parseFloat(well.LAST_OIL) || 0) * commodityPrices.crude_oil;
        const gasRevenue = (parseFloat(well.LAST_GAS) || 0) * commodityPrices.natural_gas;
        return oilRevenue + gasRevenue;
    };

    // Keep existing download functionality
    const handleDownloadCSV = () => {
        if (!wells?.length) return;

        // Helper function to escape CSV values
        const escapeCSV = (value) => {
            if (value === null || value === undefined) return '';
            const stringValue = value.toString();
            // If value contains comma, quote, or newline, wrap in quotes and escape existing quotes
            if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
                return `"${stringValue.replace(/"/g, '""')}"`;
            }
            return stringValue;
        };

        // Create CSV content
        const headers = [
            'API',
            'Well Name',
            'Operator',
            'County',
            'State',
            'Status',
            'Last Prod',
            'Last Oil',
            'Last Gas'
        ];
        
        const rows = wells.map(well => [
            escapeCSV(well.API),
            escapeCSV(well.WELLNAME),
            escapeCSV(well.CURRENTOPERATOR),
            escapeCSV(well.COUNTY),
            escapeCSV(well.STATE),
            escapeCSV(well.REPORTEDWELLSTATUS),
            escapeCSV(formatDate(well.LAST_PROD_DATE)),
            escapeCSV(well.LAST_OIL), // Don't use toLocaleString() for CSV
            escapeCSV(well.LAST_GAS)  // Don't use toLocaleString() for CSV
        ]);
        
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'well_search_results.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the URL object
    };

    return (
        <Box>
            <WellSearchSummary wells={wells} commodityPrices={commodityPrices} />
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button 
                    variant="outlined" 
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadCSV}
                >
                    DOWNLOAD CSV
                </Button>
            </Box>

            <Box sx={{ 
                height: '600px',
                overflowY: 'auto',
                overflowX: 'auto'
            }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 1 }}>
                        <tr>
                            {[
                                'API',
                                'WELL NAME',
                                'OPERATOR',
                                'COUNTY',
                                'STATE',
                                'STATUS',
                                'LAST PROD',
                                'LAST OIL',
                                'LAST GAS',
                                'GROSS REVENUE'
                            ].map(header => (
                                <td 
                                    key={header}
                                    onClick={() => sortData(header)}
                                    style={{ 
                                        border: '1px solid black', 
                                        padding: '8px', 
                                        backgroundColor: '#f5f5f5',
                                        cursor: 'pointer',
                                        textAlign: ['LAST PROD', 'LAST OIL', 'LAST GAS', 'GROSS REVENUE'].includes(header) ? 'right' : 'left'
                                    }}
                                >
                                    {header}
                                    {sortConfig.key === keyMap[header] && (
                                        <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedWells.map((well, index) => (
                            <tr 
                                key={`${well.API}-${index}`}
                                onClick={() => navigate(`/wells/${well.API}`)}
                                onMouseEnter={() => handleRowHover(well.API)}
                                style={{ cursor: 'pointer' }}
                            >
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.API}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.WELLNAME}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.CURRENTOPERATOR}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.COUNTY}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.STATE}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{well.REPORTEDWELLSTATUS}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{formatProdDate(well.LAST_PROD_DATE)}</td>
                                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{well.LAST_OIL?.toLocaleString()}</td>
                                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{well.LAST_GAS?.toLocaleString()}</td>
                                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>
                                    {calculateGrossRevenue(well).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </td>
                            </tr>
                        ))}
                        {displayCount < wells.length && (
                            <tr ref={loadingRef}>
                                <td colSpan="10" style={{ textAlign: 'center', padding: '20px' }}>
                                    <CircularProgress size={24} />
                                    <Box component="span" sx={{ ml: 2 }}>
                                        Loading more wells...
                                    </Box>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Box>
            
            {/* Progress indicator */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 2,
                color: 'text.secondary'
            }}>
                Showing {sortedWells.length} of {wells.length} wells
            </Box>
        </Box>
    );
}

WellsTable.propTypes = {
    wells: PropTypes.array.isRequired,
    commodityPrices: PropTypes.shape({
        crude_oil: PropTypes.number.isRequired,
        natural_gas: PropTypes.number.isRequired
    }).isRequired,
    searchIntent: PropTypes.string,
    onClear: PropTypes.func
};

export default WellsTable; 