// Format API number with dashes (e.g., 42-123-45678)
export const formatAPINumber = (api) => {
    if (!api) return 'N/A'
    const cleaned = api.replace(/\D/g, '')
    if (cleaned.length !== 10) return api
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 5)}-${cleaned.slice(5)}`
}

// Format currency values
export const formatCurrency = (value) => {
    if (!value && value !== 0) return '$0';
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
};

// Format numbers with commas
export const formatNumber = (value, decimals = 0) => {
    if (!value && value !== 0) return 'N/A'
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(value)
}

// Format dates
export const formatDate = (date, options = {}) => {
    if (!date) return 'N/A'
    const defaultOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    try {
        return new Date(date).toLocaleDateString('en-US', { ...defaultOptions, ...options })
    } catch (error) {
        return 'Invalid Date'
    }
}

// Format well status
export const formatWellStatus = (status) => {
    if (!status) return 'Unknown'
    const statusMap = {
        'ACTIVE': 'Active',
        'INACTIVE': 'Inactive',
        'SHUT_IN': 'Shut In',
        'ABANDONED': 'Abandoned',
        'PLUGGED': 'Plugged',
        'DRILLING': 'Drilling'
    }
    return statusMap[status.toUpperCase()] || status
}

// Format interest percentage
export const formatInterest = (value) => {
    if (!value && value !== 0) return 'N/A'
    return `${(value * 100).toFixed(8)}%`
}

// Format production values
export const formatProduction = (value, type) => {
    if (!value && value !== 0) return 'N/A'
    const units = {
        'oil': 'BBL',
        'gas': 'MCF',
        'water': 'BBL'
    }
    return `${formatNumber(value)} ${units[type] || ''}`
} 