import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Typography,
    Box,
    Button
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';

function TaxRollOwnersTable({ owners, wellName, api, well }) {
    const [orderBy, setOrderBy] = useState('NRI');
    const [order, setOrder] = useState('desc');

    const toTitleCase = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    useEffect(() => {
        console.log('TaxRollOwnersTable - Full owners data:', owners);
        if (owners?.length > 0) {
            console.log('TaxRollOwnersTable - First owner data:', owners[0]);
        }
    }, [owners]);

    useEffect(() => {
        console.log('Well object in TaxRollOwnersTable:', well);
        console.log('Remaining Value:', well?.REMAINING_VALUE);
    }, [well]);

    // Skip rendering if no owners
    if (!owners?.length) {
        return null;
    }

    const formatNRI = (value) => {
        return value ? parseFloat(value).toFixed(8) : '0.00000000';
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getSortedOwners = () => {
        return [...owners].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Handle numeric sorting for NRI
            if (orderBy === 'NRI') {
                aValue = parseFloat(aValue) || 0;
                bValue = parseFloat(bValue) || 0;
            } else {
                // String sorting for other columns
                aValue = (aValue || '').toString().toLowerCase();
                bValue = (bValue || '').toString().toLowerCase();
            }

            if (order === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            } else {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            }
        });
    };

    const SortableTableCell = ({ id, label, align = 'left' }) => (
        <TableCell 
            align={align}
            sortDirection={orderBy === id ? order : false}
        >
            <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={() => handleRequestSort(id)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    );

    const downloadOwnersCSV = () => {
        if (!owners?.length) return;

        // Create CSV content
        const headers = [
            'Owner',
            'Address 1',
            'Address 2',
            'City',
            'State',
            'ZIP',
            'NRI',
            'Interest Type'
        ];
        
        const rows = owners.map(owner => [
            owner.OWNER,
            owner['Address 1'] || '',
            owner['Address 2'] || '',
            owner.CITY || '',
            owner.STATE || '',
            owner.ZIP || '',
            formatNRI(owner.NRI),
            owner['INTEREST TYPE'] || ''
        ]);
        
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${wellName || api}_tax_roll_owners.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const formatCurrency = (num) => num ? Number(num).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) : '$0';

    const calculateEstRevenue = (record) => {
        const nri = parseFloat(record.NRI) || 0;
        const monthlyRevenue = (parseFloat(record.LAST_OIL) || 0) * 75 + // Using fixed price of $75/bbl
                              (parseFloat(record.LAST_GAS) || 0) * 3;    // Using fixed price of $3/mcf
        return monthlyRevenue * nri;
    };

    const calculateEstOffer = (record) => {
        const monthlyRevenue = calculateEstRevenue(record);
        return monthlyRevenue * 48; // 48 months of revenue
    };

    const calculateRemainingValue = (well) => {
        if (!well) return 0;
        
        // EUR BOE Revenue - Total BOE Revenue
        const eurBoeRevenue = well.EUR_BOE_REVENUE || 0;
        const totalBoeRevenue = well.CUMULATIVE_BOE_REVENUE || 0;
        
        return eurBoeRevenue - totalBoeRevenue;
    };

    const columns = [
        { 
            key: 'OWNER', 
            label: 'Owner',
            align: 'left',
            render: (value, row) => toTitleCase(row.OWNER)
        },
        { 
            key: 'ADDRESS_1', 
            label: 'Address 1',
            align: 'left',
            render: (value, row) => row.ADDRESS_1
        },
        { 
            key: 'ADDRESS_2', 
            label: 'Address 2',
            align: 'left',
            render: (value, row) => row.ADDRESS_2
        },
        { 
            key: 'CITY', 
            label: 'City',
            align: 'left',
            render: (value, row) => row.CITY
        },
        { 
            key: 'STATE', 
            label: 'State',
            align: 'left',
            render: (value, row) => row.STATE
        },
        { 
            key: 'ZIP', 
            label: 'ZIP',
            align: 'left',
            render: (value, row) => row.ZIP
        },
        { 
            key: 'NRI', 
            label: 'NRI',
            align: 'right',
            render: (value, row) => formatNRI(row.NRI)
        },
        { 
            key: 'INTEREST TYPE', 
            label: 'Interest Type',
            align: 'left',
            render: (value, row) => row['INTEREST TYPE']
        },
        { 
            key: 'EST_REVENUE', 
            label: 'Est Revenue',
            align: 'right',
            render: (value, row) => {
                const monthlyRevenue = calculateEstRevenue(row);
                return formatCurrency(monthlyRevenue);
            }
        },
        { 
            key: 'REMAINING_VALUE', 
            label: 'Remaining Value',
            align: 'right',
            render: (value, row) => {
                const nri = parseFloat(row.NRI) || 0;
                const remainingValue = calculateRemainingValue(well);
                return formatCurrency(remainingValue * nri);
            }
        },
        { 
            key: 'EST_OFFER', 
            label: 'Est Offer',
            align: 'right',
            render: (value, row) => {
                const monthlyRevenue = calculateEstRevenue(row);
                return formatCurrency(monthlyRevenue * 48);
            }
        }
    ];

    return (
        <Paper sx={{ p: 3, mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Tax Roll Owners</Typography>
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={downloadOwnersCSV}
                    disabled={!owners?.length}
                    size="small"
                >
                    Download CSV
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell id="OWNER" label="Owner" />
                            <SortableTableCell id="ADDRESS_1" label="Address 1" />
                            <SortableTableCell id="ADDRESS_2" label="Address 2" />
                            <SortableTableCell id="CITY" label="City" />
                            <SortableTableCell id="STATE" label="State" />
                            <SortableTableCell id="ZIP" label="ZIP" />
                            <SortableTableCell id="NRI" label="NRI" align="right" />
                            <SortableTableCell id="INTEREST TYPE" label="Interest Type" />
                            <SortableTableCell id="EST_REVENUE" label="Est Revenue" align="right" />
                            <SortableTableCell id="REMAINING_VALUE" label="Remaining Value" align="right" />
                            <SortableTableCell id="EST_OFFER" label="Est Offer" align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getSortedOwners().map((owner, index) => (
                            <TableRow 
                                key={`${owner.OWNER}-${index}`}
                                sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}
                            >
                                <TableCell>{toTitleCase(owner.OWNER)}</TableCell>
                                <TableCell>{owner.ADDRESS_1}</TableCell>
                                <TableCell>{owner.ADDRESS_2}</TableCell>
                                <TableCell>{owner.CITY}</TableCell>
                                <TableCell>{owner.STATE}</TableCell>
                                <TableCell>{owner.ZIP}</TableCell>
                                <TableCell align="right">{formatNRI(owner.NRI)}</TableCell>
                                <TableCell>{owner['INTEREST TYPE']}</TableCell>
                                <TableCell align="right">
                                    {formatCurrency(calculateEstRevenue(owner))}
                                </TableCell>
                                <TableCell align="right">
                                    {formatCurrency((calculateRemainingValue(well) || 0) * (parseFloat(owner.NRI) || 0))}
                                </TableCell>
                                <TableCell align="right">
                                    {formatCurrency(calculateEstOffer(owner))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

TaxRollOwnersTable.propTypes = {
    owners: PropTypes.array.isRequired,
    wellName: PropTypes.string,
    api: PropTypes.string,
    well: PropTypes.object
};

export default TaxRollOwnersTable; 